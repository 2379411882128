import ApiFetcher from "@/services/api-fetcher"
import { Account, Auth, User } from "@/models/models"
import { useMainStore } from "@/store"
import { useToast } from "vue-toastification"
import LoginSharedStateService from "./loginSharedState.service"
import { getActivePinia } from "pinia"

export default class AuthService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.syncSharedLoginState()
    this.fetcher = fetcher
  }

  async login(token: string) {
    const endpoint = "/auth/login"
    const request = { token }
    try {
      const data = await this.fetcher.httpPost<User>(endpoint, request)
      const auth = {
        user: {
          id: data.id,
          name: data.name,
          username: data.username,
          email: data.email,
          account_id: data.account_id,
          roles: data.roles,
        },
        isAuthenticated: true,
        token: data.token,
        scope: data.roles,
      } as Auth
      await useMainStore().setAuth(auth)
      this.syncSharedLoginState()
      return new Promise((resolve: any) => {
        resolve(auth)
      })
    } catch (error: any) {
      return new Promise((resolve: any, reject: any) => {
        if (error.response) {
          reject(new Error(error.response.data.detail))
        }
        reject()
      })
    }
  }

  async magic(email: string, url = "", use_www_auth = false) {
    const endpoint = "/auth/magic"
    const request = { email, url, use_www_auth }
    try {
      await this.fetcher.httpPost(endpoint, request)
      return new Promise((resolve: any) => {
        resolve()
      })
    } catch (error: any) {
      return new Promise((resolve: any, reject: any) => {
        reject(new Error(error.response.data.detail))
      })
    }
  }

  async registerMagic(email: string, url = "", use_www_auth = false) {
    const endpoint = "/auth/register-magic"
    const request = { email, url, use_www_auth }
    try {
      await this.fetcher.httpPost(endpoint, request)
      return new Promise((resolve: any) => {
        resolve()
      })
    } catch (error: any) {
      return new Promise((resolve: any, reject: any) => {
        reject(new Error(error.response.data.detail))
      })
    }
  }

  async register(email: string, use_www_auth = false) {
    const endpoint = "/auth/register"
    const request = { email, use_www_auth }
    try {
      return await this.fetcher.httpPost<User>(endpoint, request)
    } catch (error: any) {
      throw new Error(error.response.data.detail)
    }
  }

  logout() {
    try {
      const auth = {
        user: undefined,
        isAuthenticated: false,
        token: "",
        scope: [],
      } as Auth
      useMainStore().setAuth(auth)
      this.syncSharedLoginState()
    } catch (error) {
      console.log(error)
    }
  }

  setAuth(user: User) {
    const auth = {
      user: {
        id: user.id,
        name: user.name,
        username: user.username,
        email: user.email,
        account_id: user.account_id,
        roles: user.roles,
      } as User,
      isAuthenticated: true,
      token: user.token,
      scope: user.roles,
    }
    useMainStore().setAuth(auth)
    this.syncSharedLoginState()
  }

  setUserLoginState(user?: User, accounts?: Account[]) {
    if (!user) {
      LoginSharedStateService.clear()
      return
    }
    LoginSharedStateService.set(user, accounts)
  }

  async spoof(userId: string, accountId: number): Promise<User> {
    const endpoint = "/auth/spoof"
    const params = {
      uid: userId,
      aid: accountId,
    }
    try {
      const user = await this.fetcher.httpGet<User>(endpoint, params)
      this.setAuth(user)
      return user
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async unspoof(): Promise<User> {
    const endpoint = "/auth/unspoof"
    const params = {}
    try {
      const user = await this.fetcher.httpGet<User>(endpoint, params)
      this.setAuth(user)
      return user
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async listUsers(email: string): Promise<Array<User>> {
    return await this.fetcher.httpGet("/users", { email: email })
  }

  async switchAccount(accountId: number) {
    const user = await this.fetcher.httpPost<User>("/auth/switch-account", { account_id: accountId })
    this.setAuth(user)
    this.createAccountSwitchToast()
  }

  createAccountSwitchToast() {
    const accountName = useMainStore().account?.name
    useToast().success(`You are now logged in as ${accountName}`)
  }

  syncSharedLoginState() {
    if (typeof window == "undefined" || typeof document === "undefined" || getActivePinia() === undefined) {
      return
    }

    const authStoreUser = useMainStore().$state.auth.user
    const authStoreAccounts = useMainStore().$state.accounts
    this.setUserLoginState(authStoreUser, authStoreAccounts)
  }
}
// reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
// },
// methods: {
//   isEmailValid: function() {
//     return (this.email == "")? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
//   }
