<template>
  <WcOpenApiForm
    v-if="componentPointer"
    v-model="asset"
    :pointer="componentPointer"
    :errors="errors"
    :hide="['kind', 'groupId', 'groupName']"
    :submit-disabled="isSaving"
    @submit="handleSubmit" />
  <InlineLoading v-else />
</template>

<script lang="ts" setup>
import type { Asset } from "@/models/asset"
import { computed, ref } from "vue"
import WcOpenApiForm from "@/components/form/WcOpenApiForm.vue"
import { useOpenApiStore } from "@/components/form/openapi.state"
import { useAssetService } from "@/services/service-container"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import { TYPE, useToast } from "vue-toastification"

const emit = defineEmits(["on-asset-update-state"])
const props = defineProps<{ asset: Asset }>()

const toast = useToast()
const assetService = useAssetService()
const openApiStore = useOpenApiStore()

const asset = ref(props.asset)
const isSaving = ref<boolean>(false)
const errors = ref<string[]>([])

const componentPointer = computed(() => {
  const discriminator = openApiStore.dereferenceUri("#/paths/~1devices/post/requestBody/content/application~1json/schema/discriminator")
  if (!discriminator) {
    return null
  }
  return discriminator.mapping[asset.value.kind]
})

const handleSubmit = async () => {
  const toastId = toast("Saving asset…", { id: "asset-attributes", type: TYPE.DEFAULT, timeout: false })
  isSaving.value = true

  try {
    const response = await assetService.updateAsset(asset.value)
    errors.value = []
    toast.update(toastId, { content: "Asset saved!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
    emit("on-asset-update-state", response.data)
  } catch (error: any) {
    toast.update(toastId, { content: "Error updating asset", options: { type: TYPE.ERROR } })

    if (error.response?.status == 400) {
      errors.value = [error.response.data.detail]
    } else if (error.response?.status == 422) {
      errors.value = error.response.data.detail.map((error: any) => error.msg)
    } else {
      throw error
    }
  } finally {
    isSaving.value = false
  }
}
</script>
