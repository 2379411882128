export class EacRetirementItem {
  certificateId = ""
  quantity = 0
}

export class EacRetirementCreate {
  certificates: EacRetirementItem[] = []
}

export enum EacUnits {
  wh_electricity_consumed = "wh_electricity_consumed",
  wh_electricity_supplied = "wh_electricity_supplied",
  g_co2e_emitted = "g_co2e_emitted",
  g_co2e_avoided = "g_co2e_avoided",
}

export const ELECTRICITY_UNITS = [EacUnits.wh_electricity_consumed, EacUnits.wh_electricity_supplied]
export const CARBON_UNITS = [EacUnits.g_co2e_emitted, EacUnits.g_co2e_avoided]
export const POSITIVE_UNITS = [EacUnits.wh_electricity_supplied, EacUnits.g_co2e_avoided]
export const NEGATIVE_UNITS = [EacUnits.wh_electricity_consumed, EacUnits.g_co2e_emitted]

export interface EacMetric {
  amount: number
  units: EacUnits
  lower?: string
  upper?: string
}

export class EacValue {
  count: number = 0
}

export interface AccountBalanceSummary {
  active: Partial<Record<EacUnits, EacValue>>
  retired: Partial<Record<EacUnits, EacValue>>
}

export interface AccountHourlyBalanceSummary {
  hours: { [key: string]: AccountBalanceSummary }
}
