<template>
  <div v-if="asset" class="min-w-[380px]">
    <h4 class="text-heading-6 mb-4">{{ getAssetDisplayName(asset) }}</h4>
    <table class="text-body-2 mb-4 w-full text-left">
      <tr>
        <th>Status</th>
        <td>
          {{ ASSET_STATUS[asset.status as AssetStatus].summary }}
        </td>
      </tr>
      <tr>
        <th>Type</th>
        <td>{{ assetStore.formatAssetKindCategory(asset.kind) }}</td>
      </tr>
      <tr>
        <th class="pr-4 align-baseline">Address</th>
        <td>{{ asset.address }}</td>
      </tr>
    </table>
    <a :href="`/assets/${asset.id}`" class="text-body-2 underline" target="_blank">More info</a>
  </div>
</template>

<script setup lang="ts">
import type { Asset, AssetStatus } from "@/models/asset"
import { ASSET_STATUS, getAssetDisplayName } from "@/models/asset"
import { useAssetStore } from "@/modules/asset/asset.state"

const assetStore = useAssetStore()

defineProps<{ asset: Asset }>()
</script>
