import { computed, ref } from "vue"
import type { AssetKind, AssetSubmissionRow } from "@/models/asset"
import { defineStore } from "pinia"
import { useAssetService } from "@/services/service-container"
import type { CategoryInfo } from "@/modules/asset/asset.service"

export const useAssetStore = defineStore("asset", () => {
  const assetService = useAssetService()

  const assetSubmissionAccountId = ref<number | undefined>()
  const assetSubmissionKind = ref<AssetKind>()
  const assetSubmissionFile = ref<File>()
  const assetSubmissionRows = ref<AssetSubmissionRow[]>([])
  const assetSubmissionGlobalErrors = ref<string[]>([])
  const assetSubmissionGlobalWarnings = ref<string[]>([])

  const isLoading = ref<boolean>(true)
  const methodologyCategories = ref<CategoryInfo[]>([])
  const assetKinds = ref<Record<string, string>>({})
  const assetKindCategories = ref<Record<string, string>>({})

  const loadMethodologies = async () => {
    isLoading.value = true
    try {
      const categories = await assetService.listMethodologies()
      categories.forEach((category) => {
        category.methodologies.forEach((methodology) => {
          assetKinds.value[methodology.kind] = methodology.name
          assetKindCategories.value[methodology.kind] = category.name
        })
      })
      methodologyCategories.value = categories
    } finally {
      isLoading.value = false
    }
  }

  const formatAssetKind = (kind: string) => {
    return assetKinds.value[kind] || kind || ""
  }

  const formatAssetKindCategory = (kind: string) => {
    return assetKindCategories.value[kind] || kind || ""
  }

  const assetKindOptions = computed(() => {
    return Object.entries(assetKinds.value).map(([value, label]) => ({ value, label }))
  })
  const numErrorRows = computed<number>(() => assetSubmissionRows.value.filter((row) => row.errors.length > 0).length)
  const numGlobalErrors = computed<number>(() => assetSubmissionGlobalErrors.value.length)
  const hasSubmissionErrors = computed<boolean>(() => numErrorRows.value > 0 || numGlobalErrors.value > 0)
  const isSubmissionValid = computed<boolean>(() => !hasSubmissionErrors.value && assetSubmissionRows.value.length > 0)

  const numWarningRows = computed<number>(() => assetSubmissionRows.value.filter((row) => row.warnings.length > 0).length)
  const numGlobalWarnings = computed<number>(() => assetSubmissionGlobalWarnings.value.length)
  const hasSubmissionWarnings = computed<boolean>(() => numWarningRows.value > 0 || numGlobalWarnings.value > 0)

  const $reset = () => {
    assetSubmissionAccountId.value = undefined
    assetSubmissionKind.value = undefined
    assetSubmissionFile.value = undefined
    assetSubmissionRows.value = []
    assetSubmissionGlobalErrors.value = []
    assetSubmissionGlobalWarnings.value = []
  }

  loadMethodologies()

  return {
    // refs
    assetSubmissionAccountId,
    assetSubmissionFile,
    assetSubmissionGlobalErrors,
    assetSubmissionGlobalWarnings,
    assetSubmissionKind,
    assetSubmissionRows,
    methodologyCategories,
    isLoading,

    // computed
    assetKindOptions,
    hasSubmissionErrors,
    hasSubmissionWarnings,
    isSubmissionValid,
    numErrorRows,
    numWarningRows,
    numGlobalErrors,
    numGlobalWarnings,

    // methods
    $reset,
    formatAssetKind,
    formatAssetKindCategory,
  }
})
