import ApiFetcher from "@/services/api-fetcher"
import { PublicAsset } from "@/models/asset"
import { Account, MinimalUser } from "@/models/models"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"

export enum Unit {
  pennies_usd = "pennies_usd",
  g_co2e_emitted = "g_co2e_emitted",
  g_co2e_avoided = "g_co2e_avoided",
  wh_electricity_consumed = "wh_electricity_consumed",
  wh_electricity_supplied = "wh_electricity_supplied",
  devices = "devices",
}

export const ELECTRICITY_UNITS = [Unit.wh_electricity_consumed, Unit.wh_electricity_supplied]
export const CARBON_UNITS = [Unit.g_co2e_emitted, Unit.g_co2e_avoided]

export type TransactionKind =
  | "registration"
  | "minting"
  | "allocation"
  | "transfer"
  | "retirement"
  | "listing_sale"
  | "listing_earnings"
  | "purchase"
  | "payment_posted"
  | "withdrawal"
  | "fee"

export const ACTIVITY_LOG_INCLUDED_KINDS: TransactionKind[] = [
  "allocation",
  "listing_sale",
  "minting",
  "purchase",
  "retirement",
  "transfer",
  "payment_posted",
  "registration",
]

const TRANSACTION_KIND_TO_SUMMARY: { [key: string]: string } = {
  minting: "created",
  allocation: "allocated",
  transfer: "transferred",
  retirement: "retired",
  purchase: "purchased",
  listing_sale: "sold",
  listing_earnings: "sold",
  registration: "registered",
}

interface BaseTransactionDetails {
  amount: number
  units: Unit
  kind: TransactionKind
  memo?: string
  accountId: number
  kindDetails: {
    [key: string]: any
  }
}

export enum UnitCategory {
  Currency = "currency",
  Eacs = "eacs",
}

export interface PublicTransactionDetails {
  amount: number
  units: Unit
  account: {
    id: Account["id"]
    name: Account["name"] | null
  }
  kind: TransactionKind
}

export const formatEacQuantity = (details: TransactionDetails[] | PublicTransactionDetails[]) => {
  let netWhElectricity = 0
  let netgCO2e = 0
  for (const detail of details) {
    switch (detail.units) {
      case Unit.wh_electricity_consumed:
        netWhElectricity -= detail.amount
        break
      case Unit.wh_electricity_supplied:
        netWhElectricity += detail.amount
        break
      case Unit.g_co2e_avoided:
        netgCO2e += detail.amount
        break
      case Unit.g_co2e_emitted:
        netgCO2e -= detail.amount
        break
    }
  }

  if (netWhElectricity != 0) {
    return getFormattedEacQuantity(netWhElectricity, EacMeasurementParameter.Electricity, 2, "exceptZero")
  } else if (netgCO2e != 0) {
    return getFormattedEacQuantity(netgCO2e, EacMeasurementParameter.GhgEmissions, 2, "exceptZero")
  } else {
    return null
  }
}

export class Transaction {
  id: string
  accountId: number
  createdTime: string
  details: TransactionDetails[]

  constructor(id: string, accountId: number, createdTime: string, details: TransactionDetails[]) {
    this.id = id
    this.accountId = accountId
    this.createdTime = createdTime
    this.details = details
  }

  getAmountsByUnit(options: { category?: UnitCategory }): Partial<{ [key in Unit]: number }> {
    let amountsByUnit: [Unit, number][] = this.details.map((detail) => [detail.units, detail.amount])
    if (this.details[0].kind === "purchase") {
      amountsByUnit.push([Unit.pennies_usd, this.details[0].kindDetails.paymentPenniesUsd])
    }
    if (options.category === UnitCategory.Currency) {
      amountsByUnit = amountsByUnit.filter(([unit]) => [Unit.pennies_usd].includes(unit))
    } else if (options.category === UnitCategory.Eacs) {
      amountsByUnit = amountsByUnit.filter(([unit]) =>
        [Unit.wh_electricity_consumed, Unit.wh_electricity_supplied, Unit.g_co2e_emitted, Unit.g_co2e_avoided].includes(unit)
      )
    }
    return Object.fromEntries(amountsByUnit)
  }

  public get Amount() {
    const eacQuantity = formatEacQuantity(this.details)
    if (eacQuantity !== null) {
      return eacQuantity
    } else {
      return { quantity: this.details[0].amount, unit: this.details[0].units }
    }
  }

  public get Summary() {
    const description = TRANSACTION_KIND_TO_SUMMARY[this.details[0].kind] ?? "transacted"
    if (this.details[0].units === Unit.devices) {
      const unit = this.details[0].amount === 1 ? "asset" : "assets"
      return `${unit} ${description}`
    } else {
      return `${this.Amount?.unit} of EACs ${description}`
    }
  }
}

export class PublicTransaction {
  id: string
  createdTime: string
  details: PublicTransactionDetails[]
  devices: PublicAsset[]

  constructor(id: string, createdTime: string, details: PublicTransactionDetails[], devices: PublicAsset[] = []) {
    this.id = id
    this.createdTime = createdTime
    this.details = details
    this.devices = devices
  }

  public get Amount() {
    return formatEacQuantity(this.details)
  }
}

export interface Payment {
  id: number
  penniesUsd: number
  status: "payment_pending" | "completed" | "canceled"
  paidTime?: string
  createdTime: string
  memo?: string
  user: MinimalUser
  account: Account
}

interface RegistrationTransactionDetails extends BaseTransactionDetails {
  kind: "registration"
  kindDetails: {
    deviceIds: number[]
  }
}

interface MintingTransactionDetails extends BaseTransactionDetails {
  kind: "minting"
  kindDetails: {
    deviceId: number
  }
}

interface AllocationTransactionDetails extends BaseTransactionDetails {
  kind: "allocation"
  kindDetails: {
    deviceId: number
  }
}

interface TransferTransactionDetails extends BaseTransactionDetails {
  kind: "transfer"
}

interface RetirementTransactionDetails extends BaseTransactionDetails {
  kind: "retirement"
}

interface ListingSaleTransactionDetails extends BaseTransactionDetails {
  kind: "listing_sale"
}

interface ListingEarningsTransactionDetails extends BaseTransactionDetails {
  kind: "listing_earnings"
}

interface PurchaseTransactionDetails extends BaseTransactionDetails {
  kind: "purchase"
}

interface PaymentPostedTransactionDetails extends BaseTransactionDetails {
  kind: "payment_posted"
  kindDetails: {
    paymentId: number
    paymentAmount: number
  }
}

interface WithdrawalTransactionDetails extends BaseTransactionDetails {
  kind: "withdrawal"
}

interface FeeTransactionDetails extends BaseTransactionDetails {
  kind: "fee"
}

export type TransactionDetails =
  | RegistrationTransactionDetails
  | MintingTransactionDetails
  | AllocationTransactionDetails
  | TransferTransactionDetails
  | RetirementTransactionDetails
  | ListingSaleTransactionDetails
  | ListingEarningsTransactionDetails
  | PaymentPostedTransactionDetails
  | PurchaseTransactionDetails
  | WithdrawalTransactionDetails
  | FeeTransactionDetails

export default class TransactionService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  listTransactions = async (options: { kind?: TransactionKind[]; url?: string; per_page?: number }) => {
    let transactions
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/transactionsv2")
      const parsedUrl = url.slice(pathIndex)
      transactions = await this.fetcher.httpGetPaginated<Transaction[]>(parsedUrl, {})
    } else {
      transactions = await this.fetcher.httpGetPaginated<Transaction[]>(`/transactionsv2`, queryOptions)
    }
    return {
      pageInfo: transactions.pageInfo,
      data: transactions.data.map(
        (transaction) => new Transaction(transaction.id, transaction.accountId, transaction.createdTime, transaction.details)
      ),
    }
  }

  listTransactionsPublic = async (options: { kind?: string[]; url?: string; per_page?: number }) => {
    let transactions
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/transactions/public")
      const parsedUrl = url.slice(pathIndex)
      transactions = await this.fetcher.httpGetPaginated<PublicTransaction[]>(parsedUrl, {})
    } else {
      transactions = await this.fetcher.httpGetPaginated<PublicTransaction[]>(`/transactions/public`, queryOptions)
    }
    return {
      pageInfo: transactions.pageInfo,
      data: transactions.data.map(
        (transaction) => new PublicTransaction(transaction.id, transaction.createdTime, transaction.details, transaction.devices)
      ),
    }
  }

  listTransactionsAdmin = async (options: { kind?: string[]; deviceId?: number; url?: string; per_page?: number }) => {
    let transactions
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/transactions/admin")
      const parsedUrl = url.slice(pathIndex)
      transactions = await this.fetcher.httpGetPaginated<PublicTransaction[]>(parsedUrl, {})
    } else {
      transactions = await this.fetcher.httpGetPaginated<PublicTransaction[]>(`/transactions/admin`, queryOptions)
    }
    return {
      pageInfo: transactions.pageInfo,
      data: transactions.data.map(
        (transaction) => new PublicTransaction(transaction.id, transaction.createdTime, transaction.details, transaction.devices)
      ),
    }
  }

  getPayment = async (paymentId: number) => {
    return await this.fetcher.httpGet<Payment>(`/payments/${paymentId}`, {})
  }

  getCashBalance = async () => {
    return await this.fetcher.httpGet<number>("/transactionsv2/balance/cash", {})
  }
}
