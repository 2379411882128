<template>
  <WcTable
    :data="assetsData"
    :columns="[
      { key: 'name', label: 'Name', align: 'left', thClass: 'w-2/12' },
      { key: 'updatedDate', label: 'Last Update', align: 'left', thClass: 'w-2/12' },
      { key: 'status', label: 'Status', align: 'left', thClass: 'w-2/12' },
      { key: 'location', label: 'Location', align: 'left', thClass: 'w-2/12' },
      ...(showKind ? [{ key: 'kind', label: 'Resource type', align: 'left', thClass: 'w-2/12' } as TableHeader] : []),
      ...(showGroup ? [{ key: 'group', label: 'Group', align: 'left', thClass: 'w-1/12' } as TableHeader] : []),
      ...(showEacs ? [{ key: 'eacs', label: 'Net EACs', align: 'right', thClass: 'w-1/12' } as TableHeader] : []),
      ...(showDelete ? [{ key: 'delete', label: '', align: 'right', thClass: 'w-1/12' } as TableHeader] : []),
    ]"
    row-id-field="id"
    :table-class="`text-body-3 min-w-main-content table-fixed ${tableClass}`"
    tr-class="cursor-pointer hover:bg-neutral-10 transition-colors border-b border-neutral-20"
    th-class="text-left py-2"
    td-class="p-3"
    :select-on-row-click="selectOnRowClick"
    :sortable="true">
    <template #status="{ row }: { row: AssetRow }">
      <div class="flex items-center">
        <div
          class="-my-1 rounded px-3 py-1"
          :class="{
            'bg-info-light': row.status === AssetStatus.approved,
            'bg-warning-light': row.status === AssetStatus.pending,
            'bg-error-light': row.status === AssetStatus.changes_requested,
            'bg-neutral-10': row.status === AssetStatus.draft,
          }">
          {{ row.statusSummary }}
        </div>
        <ToolTip v-if="row.reviewNotes" class="whitespace-normal">
          <div>{{ row.reviewNotes }}</div>
        </ToolTip>
      </div>
    </template>
    <template #delete="{ row }: { row: AssetRow }">
      <WcButton icon="wc-carbon:delete" size="extra-small" text="Delete" @click="(event) => emit('row-delete-clicked', row.id, event)" />
    </template>
  </WcTable>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import { WcButton } from "@/components/button"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import { ASSET_STATUS, AssetStatus } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import type { AssetWithSummary } from "@/modules/asset/asset.service"
import { useAssetStore } from "@/modules/asset/asset.state"
import { netEacsFromValues } from "@/modules/registry/registry.utils"

const assetStore = useAssetStore()

type AssetRow = {
  id: number
  name: string
  kind: string
  location: string
  reviewNotes?: string
  status: AssetStatus
  statusSummary: string
  updatedDate: string
}

const props = defineProps<{
  assets: AssetWithSummary[]
  selectOnRowClick?: boolean
  showDelete?: boolean
  showEacs?: boolean
  showGroup?: boolean
  showKind?: boolean
  tableClass?: string
}>()

const getAssetNetEacs = (group: AssetWithSummary) => {
  if ((group?.summary?.eacs?.sum?.length ?? 0) === 0) return ""
  const netEacs = netEacsFromValues(group.summary.eacs.sum)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledShort).toString()
}

const emit = defineEmits<{ "row-delete-clicked": [rowId: number, event: MouseEvent] }>()
const assetsData = computed<AssetRow[]>(() => {
  return props.assets.map((asset) => {
    return {
      id: asset.id,
      name: asset.name || `Asset ${asset.id}`,
      kind: assetStore.formatAssetKindCategory(asset.kind),
      group: asset.group?.name || "",
      location: `${asset.city}, ${asset.state}`,
      reviewNotes: asset.reviewNotes,
      status: asset.status,
      statusSummary: ASSET_STATUS[asset.status].summary || asset.status,
      updatedDate: new Date(asset.updatedTime).toISOString().split("T")[0],
      eacs: props.showEacs ? getAssetNetEacs(asset) : "",
    }
  })
})
</script>
