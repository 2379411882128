<template>
  <nav class="pb-[30px] pt-8">
    <div class="mb-[30px] flex justify-start">
      <WcRouterLink
        :route="{ name: 'wc-dashboard' }"
        is-back-link
        class="ph-no-capture text-body-3 flex items-center justify-center gap-2 fill-blue-70 text-blue-70 transition-colors hover:fill-highlight">
        <WcCTA text="Back to Dashboard" icon="wc-carbon:arrow-left" size="small" />
      </WcRouterLink>
    </div>
    <ul class="flex flex-wrap items-center justify-start">
      <li>
        <h2 class="text-subheading-large-bold mr-[31px]">Benchmarking</h2>
      </li>
      <li class="text-subheading-2" :class="getMenuClass(['wc-accounting'])">
        <router-link to="/accounting" class="ph-no-capture block px-6 py-[10px]">Accounting</router-link>
      </li>
      <li class="text-subheading-2" :class="getMenuClass(['wc-load'])">
        <router-link to="/load" class="ph-no-capture block px-6 py-[10px]" aria-current="page">Load</router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router"

import { WcCTA } from "@/components/button"
import WcRouterLink from "@/components/WcRouterLink.vue"

const route = useRoute()

const getMenuClass = (prefixes: Array<string>) => {
  return prefixes.find((prefix) => route.name?.toString().indexOf(prefix) === 0) ? "active" : ""
}
</script>

<style scoped lang="scss">
.active {
  @apply border-b-[2px] border-b-sagetone -mb-[3px];
}
</style>
