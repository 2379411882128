<template>
  <section class="mx-4 mt-4 w-full max-w-main-content">
    <div>
      <WcCarbonAccountingDashboardHero ref="dashboardHero" :asset-ids="assetIds" :order-ids="orderIds"></WcCarbonAccountingDashboardHero>
      <div class="mb-4 mt-8 flex flex-row items-center justify-center gap-2 text-lg">
        <div class="h-px shrink grow basis-1/2 bg-sagetone"></div>
        <div class="mt-0.5 shrink-0 grow-0 dark:text-white">{{ assetsStartDateFormatted }} – {{ assetsEndDateFormatted }}</div>
        <div class="h-px shrink grow basis-1/2 bg-sagetone"></div>
      </div>
      <WcCarbonAccountingDashboardAssets :site-rows="siteRows" :market-asset-rows="marketAssetRows"></WcCarbonAccountingDashboardAssets>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watchEffect } from "vue"
import { format } from "date-fns"
// Stores
import { useCarbonAccountingDashboardStore } from "./carbonAccountingDashboard.state"
import { useSitesStore } from "@/store/sites"
// Models
import { gModelDict } from "@/global"
import { LoadshapeSummary } from "@/models/loadshape"
import { LoadshapeSummaryQueryRequest } from "@/models/loadshapeQueryRequest"
import { Site } from "@/models/site"
import { SiteAsset } from "@/models/siteAsset"
import { Order } from "@/models/order"
// Services
// Components
import WcCarbonAccountingDashboardHero from "./components/WcCarbonAccountingDashboardHero.vue"
import { SideNavRow } from "@/components/layout/layout.model"
import WcCarbonAccountingDashboardAssets from "./components/WcCarbonAccountingDashboardAssets.vue"
import { isMarketAssetType } from "./carbonAccountingDashboard.helper"
import { useOrdersService, useQueryService } from "@/services/service-container"

const dashboardHero = ref()

const dashboardStore = useCarbonAccountingDashboardStore()
const sitesStore = useSitesStore()

const queryService = useQueryService()
const orderService = useOrdersService()

const siteRows = ref(new Array<any>())
const marketAssetRows = ref(new Array<any>())
const orderRows = ref(new Array<any>())
const marketAssetData = ref(new Array<any>())
const assetIds = computed<Array<number>>(() => siteRows.value.flatMap((r: any) => r.site.assets.map((a: SiteAsset) => a.id)))
const orderIds = computed<Array<number>>(() => orderRows.value.map((r: any) => r.id))

const assetsStartDateFormatted = computed(() => format(dashboardStore.assetsDateRange.startDate, "LLLL d, y"))
const assetsEndDateFormatted = computed(() => format(dashboardStore.assetsDateRange.endDate, "LLLL d, y"))

const createSiteRows = (sites: Array<Site>, summaries: Array<LoadshapeSummary>) => {
  const filteredSites = sites.filter((site) => site.assets.find((asset) => asset.gridPurpose !== "market_based_production"))
  const rows = filteredSites.map(SideNavRow.fromSite)
  rows.forEach((row: SideNavRow) => {
    row.rows.forEach((assetRow: SideNavRow, index) => {
      if (Object.hasOwn(gModelDict, assetRow.type)) {
        row.rows[index].icon = gModelDict[assetRow.type].icon
      }
      const summary = summaries.find((a: LoadshapeSummary) => a.sourceId === assetRow.sourceId)
      if (summary) {
        row.rows[index] = SideNavRow.fromLoadshapeSummary(assetRow, summary)
        row.carbonSumTonnesCo2e += row.rows[index].carbonSumTonnesCo2e
        row.energySumMwh += row.rows[index].energySumMwh
        row.locationalCarbonBasedEnergySumMwh += row.rows[index].locationalCarbonBasedEnergySumMwh
      }
    })
    row.carbonRatio = row.energySumMwh !== 0 ? Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2)) : 0
  })
  return rows.sort((a: SideNavRow, b: SideNavRow) => a.name.localeCompare(b.name))
}

const createMarketAssetRows = (assets: Array<SiteAsset>, summaries: Array<LoadshapeSummary>) => {
  return assets
    .map((asset) => {
      const summary = summaries.find((s: LoadshapeSummary) => s.sourceId === asset.id) || new LoadshapeSummary()
      const row = {
        id: asset.id,
        name: asset.name,
        status: "REC", // ToDo: Need to handle this better
        carbonSumTonnesCo2e: summary.marketBasedCarbonSumKgCo2 / 1000,
        energySumMwh: summary.marketBasedEnergySumMwh,
        carbonRatio: 0,
        asset: asset,
      }
      row.carbonRatio = row.energySumMwh !== 0 ? Math.abs(Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2))) * -1 : 0
      return row
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}

const createOrderRows = (orders: Array<Order>) => {
  return orders
    .map((order) => {
      const row = {
        id: order.id,
        name: order.portfolio.name,
        status: order.status,
        carbonSumTonnesCo2e: 0,
        energySumMwh: 0,
        carbonRatio: 0,
        order: order,
      }
      row.carbonRatio = row.energySumMwh !== 0 ? Math.abs(Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2))) * -1 : 0
      return row
    })
    .sort((a, b) => a.name.localeCompare(b.name))
}

onMounted(async () => {
  dashboardStore.orders = await orderService.listOrders()
})

const marketAssets = computed(() => {
  return sitesStore.assets.filter((a: SiteAsset) => isMarketAssetType(a.gridPurpose))
})

watchEffect(async () => {
  const marketAssetRequest = new LoadshapeSummaryQueryRequest(
    marketAssets.value.map((a: SiteAsset) => a.id),
    dashboardStore.assetsDateRange.startDate,
    dashboardStore.assetsDateRange.endDate
  )

  const siteSummaryRequest = new LoadshapeSummaryQueryRequest(
    sitesStore.assetIds,
    dashboardStore.assetsDateRange.startDate,
    dashboardStore.assetsDateRange.endDate
  )
  const [siteSummaries, loadshapeSummaries] = await Promise.all([
    // TODO list orders query
    queryService.listLoadshapeSummaries(siteSummaryRequest),
    queryService.listLoadshapeSummaries(marketAssetRequest),
  ])
  siteRows.value = createSiteRows(sitesStore.sites, siteSummaries)
  marketAssetData.value = createMarketAssetRows(marketAssets.value, loadshapeSummaries)
  orderRows.value = createOrderRows(dashboardStore.orders)
  marketAssetRows.value = [...orderRows.value, ...marketAssetData.value].sort((a, b) => a.name.localeCompare(b.name))
})
</script>
<style scoped lang="scss">
table tbody {
  max-height: 300px;
}
</style>
./carbonAccountingDashboard.helper ./carbonAccountingDashboard.state
