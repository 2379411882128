import { Coordinates } from "./models"
import { ASSET_CATEGORY, AssetCategory } from "./asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "./order"

export enum StoryStatusType {
  draft = "draft",
  published = "published",
  archived = "archived",
}

export class Story {
  id!: number
  name!: string
  subtitle!: string | null
  shortDescription!: string | null
  longDescription!: string | null
  bodyContent!: string | null
  locationDescription!: string | null
  dateRangeDescription!: string | null
  priceDescription!: string | null
  imageUrl!: string | null
  coordinates?: Coordinates[]
  impacts!: string[] | null
  impactQuote!: string | null
  impactQuoteAttribution!: string | null
  impactStatements!: string[] | null
  availabilityDescription!: string | null
  deviceKindDescription!: string | null
  methodologyDescription!: string | null
  volumeDescription!: string | null
  measurementAndVerificationPlanTitle!: string | null
  measurementAndVerificationPlanUrl!: string | null
}

export class AdminStory extends Story {
  status!: StoryStatusType
  createdTime!: string
  updatedTime!: string
}

type PriceRange = {
  lower: number
  upper: number | null
}

export type Methodology = {
  name: string
  url: string
}

export class MarketplaceStory extends Story {
  listingId!: number
  totalEacs!: number
  totalGco2!: number
  totalDevices!: number
  states!: string[]
  deviceCategories!: AssetCategory[]
  devicePriceRangePerUnitInPenniesUsdPerMwh!: PriceRange | null
  devicePriceRangePerUnitInPenniesUsdPerTco2!: PriceRange | null
  deviceMethodologies!: Methodology[]
}

// TODO we are casting things to `MarketplaceStory` in some places, so I'm not sure which
// of these properties are _actually_ required.  For now, i'm checking for the minimal amount
// needed to make the tests pass.  We can probably just fix the tests to use objects that have
// the expected keys on them instead of casting
function isMarketplaceStory(story?: Story | MarketplaceStory): story is MarketplaceStory {
  if (!story) return false
  if (
    "totalEacs" in story ||
    "totalGco2" in story ||
    "deviceCategories" in story ||
    "devicePriceRangePerUnitInPenniesUsdPerMwh" in story ||
    "devicePriceRangePerUnitInPenniesUsdPerTco2" in story ||
    "deviceMethodologies" in story
  ) {
    return true
  }
  return false
}

export const marketplaceStoryVolume = (story: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story)) {
    return ""
  }
  if (!story.totalEacs && !story.totalGco2) {
    return ""
  }
  const totalEacs = story?.totalEacs || 0
  const totalGco2 = story?.totalGco2 || 0

  const formattedEacs = getFormattedEacQuantity(totalEacs, EacMeasurementParameter.Electricity, 1).toString()
  const formattedCarbon = getFormattedEacQuantity(totalGco2, EacMeasurementParameter.CarbonDioxide, 1).toString()

  if (totalEacs && totalGco2) {
    return `${formattedEacs} (${formattedCarbon})`
  } else if (totalEacs) {
    return formattedEacs
  } else {
    return formattedCarbon
  }
}

export const marketplaceStoryCategories = (story: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story) || !story.deviceCategories) {
    return ""
  }
  return story.deviceCategories.map((kind) => ASSET_CATEGORY[kind]).join(", ")
}

const priceOrRange = (prices: PriceRange | null): string => {
  if (prices == null) {
    return ""
  }

  if (prices.upper === null) {
    return `${prices.lower / 100}`
  }

  return `${prices.lower / 100}-${prices.upper / 100}`
}

export const marketplaceStoryPrice = (story?: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story)) {
    return story?.priceDescription ?? ""
  }

  if (story.priceDescription) {
    return story.priceDescription
  }

  if (!story.devicePriceRangePerUnitInPenniesUsdPerMwh && !story.devicePriceRangePerUnitInPenniesUsdPerTco2) {
    return ""
  }

  const priceRangeMwh = priceOrRange(story?.devicePriceRangePerUnitInPenniesUsdPerMwh)
  const priceRangeTco2 = priceOrRange(story?.devicePriceRangePerUnitInPenniesUsdPerTco2)

  if (priceRangeMwh.length > 0 && priceRangeTco2.length > 0) {
    return `$${priceRangeMwh}/MWh ($${priceRangeTco2}/tCO2)`
  } else if (priceRangeMwh.length > 0) {
    return `$${priceRangeMwh}/MWh`
  } else if (priceRangeTco2.length > 0) {
    return `$${priceRangeTco2}/tCO2`
  } else {
    return ""
  }
}

export const marketplaceStoryMethodologies = (story: Story | MarketplaceStory) => {
  if (!isMarketplaceStory(story)) {
    return []
  }
  return story.deviceMethodologies ?? []
}
