<template>
  <WcCard header-text="Path to 24/7 Carbon-free Energy" card-class="w-full" header-class="text-center">
    <div class="relative flex h-full items-stretch gap-4">
      <div class="dark:bg-dark-blue-99 shrink-0 grow-0 basis-full">
        <div class="content h-[280px]">
          <div v-if="!pageState.isLoadshapeLoading && pageState.error" class="flex h-full items-center justify-center bg-error-light">
            <span class="text-error">{{ pageState.error }}</span>
          </div>
          <WcLoadingSpinner v-if="pageState.isLoadshapeLoading" />
          <div v-else>
            <div v-show="hasData" class="flex justify-center">
              <WcCarbonAccountingDashboardChartCFE
                v-show="!pageState.isLoadshapeLoading"
                height="240"
                width="1000"
                :data="graphData"
                data-cy="chart-dashboard" />
            </div>
            <div
              v-show="!hasData && props.assetIds.length === 0 && props.orderIds.length === 0"
              class="flex h-[240px] items-center justify-center text-sagetone">
              Add <router-link class="mx-1 text-blue-70 underline" :to="{ name: 'wc-load' }">Locational Assets</router-link>
              to get started.
            </div>
          </div>
        </div>
      </div>
    </div>
  </WcCard>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, PropType, watch } from "vue"
import { addMonths, startOfMonth, format, parse, endOfMonth } from "date-fns"
// Models
import { LoadshapeGroupedSummary, LoadshapeSummaryRow } from "@/models/loadshape"
import {
  LoadshapeAssetActualsQueryRequest,
  LoadshapeAssetForecastQueryRequest,
  // LoadshapeOrderForecastQueryRequest,
  QueryGroupByType,
} from "@/models/loadshapeQueryRequest"
// Services
import { GraphHelper } from "@/services/table.helper"
// Components
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import WcCard from "../../../components/WcCard.vue"
import WcCarbonAccountingDashboardChartCFE from "./WcCarbonAccountingDashboardChartCFE.vue"
import { DashboardGraphStackData, getYearMonth } from "../carbonAccountingDashboard.helper"
import { useQueryService } from "@/services/service-container"
import { isEqual } from "lodash"

const props = defineProps({
  assetIds: { type: Array as PropType<Array<number>>, default: new Array<number>(), required: true },
  orderIds: { type: Array as PropType<Array<number>>, default: new Array<number>(), required: true },
})

const pageState: { error: undefined | string; isLoadshapeLoading: boolean } = reactive({
  error: undefined,
  isLoadshapeLoading: true,
})
const hasData = computed(() => {
  return Object.prototype.hasOwnProperty.call(graphData.value, "netCarbonBasedActualMWh") && graphData.value.netCarbonBasedActualMWh.length > 0
})

// TODO: toDateYearMonths should be of the range [January this year, now -2 months]
const currentMonth = startOfMonth(new Date())
const pastYearMonths = GraphHelper.getYearMonths(addMonths(currentMonth, -18), addMonths(currentMonth, -1))
const forecastYearMonths = GraphHelper.getYearMonths(currentMonth, addMonths(currentMonth, 12))
const allYearMonths = [...pastYearMonths, ...forecastYearMonths]

const startDate = addMonths(currentMonth, -18)
const currentDate = endOfMonth(addMonths(new Date(), -1))
const endDate = addMonths(currentMonth, 12)
let assetsActualsLoadshape: LoadshapeGroupedSummary = new LoadshapeGroupedSummary()
let assetsForecastLoadshape: LoadshapeGroupedSummary = new LoadshapeGroupedSummary()
const ordersForecastLoadshape: LoadshapeGroupedSummary = new LoadshapeGroupedSummary()
let graphDataMonthly = new DashboardGraphStackData()
const graphData = ref<DashboardGraphStackData>(new DashboardGraphStackData())
const zeroThreshold = 0.1 // If any MWh values are less than this value, just treat it as 0 for graph data.
const queryService = useQueryService()

const updateGraphDataMonthly = () => {
  // To be extra careful with calculations in case of missing loadhours, we hold onto the yearMonths as keys.
  const dataByMonth = allYearMonths.reduce((x, yearMonth) => ({ ...x, [yearMonth]: {} }), {}) as any
  assetsActualsLoadshape.data.forEach((lh: LoadshapeSummaryRow) => {
    const yearMonth = getYearMonth(lh.datetime)
    dataByMonth[yearMonth].asset = lh
  })
  assetsForecastLoadshape.data.forEach((lh: LoadshapeSummaryRow) => {
    const yearMonth = getYearMonth(lh.datetime)
    dataByMonth[yearMonth].asset = lh
  })
  ordersForecastLoadshape.data.forEach((lh: LoadshapeSummaryRow) => {
    const yearMonth = getYearMonth(lh.datetime)
    dataByMonth[yearMonth].order = lh
  })
  const monthLabels = Object.keys(dataByMonth)
  const data = Object.values(dataByMonth)

  // locational
  const locationalCarbonBasedMWh = data.map((datum: any) => {
    return datum.asset ? datum.asset.locationalCarbonBasedEnergySumMwh : 0
  })
  const locationalCarbonFreeMWh = data.map((datum: any) => {
    return datum.asset ? datum.asset.locationalEnergySumMwh - datum.asset.locationalCarbonBasedEnergySumMwh : 0
  })
  // market (RECs + purchases)
  const marketCarbonFreeMWh = data.map((datum: any) => {
    let value = datum.asset ? datum.asset.marketBasedProductionEnergySumMwh : 0
    value += datum.order ? datum.order.marketBasedProductionEnergySumMwh : 0
    return value
  })
  // net carbon based energy (positive: consumption, negative: production)
  const netCarbonBasedMWh = data.map((datum: any, index: number) => {
    let value = Math.max(locationalCarbonBasedMWh[index] + marketCarbonFreeMWh[index], 0)
    if (value < zeroThreshold) value = 0
    return value
  })
  const marketCarbonFreeConsumptionMWh = marketCarbonFreeMWh.map((value: number, index: number) => {
    return Math.min(locationalCarbonBasedMWh[index], -1 * value)
  })
  const marketCarbonFreeProductionMWh = marketCarbonFreeMWh.map((value: number, index: number) => {
    if (-1 * value > locationalCarbonBasedMWh[index]) {
      return locationalCarbonBasedMWh[index] + value
    } else {
      return 0
    }
  })
  const netCarbonBasedActualMWh = [...netCarbonBasedMWh.slice(0, pastYearMonths.length), ...new Array(forecastYearMonths.length).fill(0)]
  const netCarbonBasedForecastedMWh = [...new Array(pastYearMonths.length).fill(0), ...netCarbonBasedMWh.slice(pastYearMonths.length)]
  const locationalCarbonFreeActualMWh = [...locationalCarbonFreeMWh.slice(0, pastYearMonths.length), ...new Array(forecastYearMonths.length).fill(0)]
  const locationalCarbonFreeForecastedMWh = [...new Array(pastYearMonths.length).fill(0), ...locationalCarbonFreeMWh.slice(pastYearMonths.length)]
  const marketCarbonFreeActualMWh = [
    ...marketCarbonFreeConsumptionMWh.slice(0, pastYearMonths.length),
    ...new Array(forecastYearMonths.length).fill(0),
  ]
  const marketCarbonFreeForecastedMWh = [...new Array(pastYearMonths.length).fill(0), ...marketCarbonFreeConsumptionMWh.slice(pastYearMonths.length)]
  const marketCarbonFreeProductionActualMWh = [
    ...marketCarbonFreeProductionMWh.slice(0, pastYearMonths.length),
    ...new Array(forecastYearMonths.length).fill(0),
  ]
  const marketCarbonFreeProductionForecastedMWh = [
    ...new Array(pastYearMonths.length).fill(0),
    ...marketCarbonFreeProductionMWh.slice(pastYearMonths.length),
  ]
  graphDataMonthly = {
    monthLabels,
    dateRanges: [],
    netCarbonBasedActualMWh,
    netCarbonBasedForecastedMWh,
    locationalCarbonFreeActualMWh,
    locationalCarbonFreeForecastedMWh,
    marketCarbonFreeActualMWh,
    marketCarbonFreeForecastedMWh,
    marketCarbonFreeProductionActualMWh,
    marketCarbonFreeProductionForecastedMWh,
  }
}

const updateGraphDataRolling = () => {
  const newGraphData: DashboardGraphStackData = {
    monthLabels: [] as string[],
    dateRanges: [] as Date[][],
    locationalCarbonFreeActualMWh: [] as number[],
    locationalCarbonFreeForecastedMWh: [] as number[],
    marketCarbonFreeActualMWh: [] as number[],
    marketCarbonFreeForecastedMWh: [] as number[],
    marketCarbonFreeProductionActualMWh: [] as number[],
    marketCarbonFreeProductionForecastedMWh: [] as number[],
    netCarbonBasedActualMWh: [] as number[],
    netCarbonBasedForecastedMWh: [] as number[],
  }
  for (let m = 0; m < 19; m++) {
    let [
      locationalCarbonFreeActualMWh,
      locationalCarbonFreeForecastedMWh,
      marketCarbonFreeActualMWh,
      marketCarbonFreeForecastedMWh,
      marketCarbonFreeProductionActualMWh,
      marketCarbonFreeProductionForecastedMWh,
      netCarbonBasedActualMWh,
      netCarbonBasedForecastedMWh,
    ] = [0, 0, 0, 0, 0, 0, 0, 0]
    const startingYearMonth = graphDataMonthly.monthLabels[m]
    const endingYearMonth = graphDataMonthly.monthLabels[m + 11]
    const startingDate = startOfMonth(parse(startingYearMonth, "y-MM", new Date()))
    const endingDate = endOfMonth(parse(endingYearMonth, "y-MM", new Date()))
    const monthLabel = `${format(endingDate, "LLL")}\n${format(endingDate, "y")}`

    const dateRange = [startingDate, endingDate]
    newGraphData.monthLabels.push(monthLabel)
    newGraphData.dateRanges.push(dateRange)
    for (let n = 0; n < 12; n++) {
      locationalCarbonFreeActualMWh += graphDataMonthly.locationalCarbonFreeActualMWh[m + n]
      locationalCarbonFreeForecastedMWh += graphDataMonthly.locationalCarbonFreeForecastedMWh[m + n]
      marketCarbonFreeActualMWh += graphDataMonthly.marketCarbonFreeActualMWh[m + n]
      marketCarbonFreeForecastedMWh += graphDataMonthly.marketCarbonFreeForecastedMWh[m + n]
      marketCarbonFreeProductionActualMWh += graphDataMonthly.marketCarbonFreeProductionActualMWh[m + n]
      marketCarbonFreeProductionForecastedMWh += graphDataMonthly.marketCarbonFreeProductionForecastedMWh[m + n]
      netCarbonBasedActualMWh += graphDataMonthly.netCarbonBasedActualMWh[m + n]
      netCarbonBasedForecastedMWh += graphDataMonthly.netCarbonBasedForecastedMWh[m + n]
    }
    newGraphData.netCarbonBasedActualMWh.push(netCarbonBasedActualMWh)
    newGraphData.netCarbonBasedForecastedMWh.push(netCarbonBasedForecastedMWh)
    newGraphData.locationalCarbonFreeActualMWh.push(locationalCarbonFreeActualMWh)
    newGraphData.locationalCarbonFreeForecastedMWh.push(locationalCarbonFreeForecastedMWh)
    newGraphData.marketCarbonFreeActualMWh.push(marketCarbonFreeActualMWh)
    newGraphData.marketCarbonFreeForecastedMWh.push(marketCarbonFreeForecastedMWh)
    newGraphData.marketCarbonFreeProductionActualMWh.push(marketCarbonFreeProductionActualMWh)
    newGraphData.marketCarbonFreeProductionForecastedMWh.push(marketCarbonFreeProductionForecastedMWh)
  }
  graphData.value = newGraphData
}

async function fetchGraphData(assetIds: Array<number>) {
  try {
    const [_assetsActualsLoadshape, _assetsForecastLoadshape] = await Promise.all([
      queryService.getLoadshapeGroupedSummary(new LoadshapeAssetActualsQueryRequest(assetIds, startDate, currentDate, QueryGroupByType.YearMonthly)),
      queryService.getLoadshapeGroupedSummary(new LoadshapeAssetForecastQueryRequest(assetIds, currentDate, endDate, QueryGroupByType.YearMonthly)),
      // queryService.getLoadshapeGroupedSummary(new LoadshapeOrderForecastQueryRequest(orderIds, currentDate, endDate, QueryGroupByType.YearMonthly)),
    ])
    assetsActualsLoadshape = _assetsActualsLoadshape
    assetsForecastLoadshape = _assetsForecastLoadshape
    // ordersForecastLoadshape = _ordersForecastLoadshape
  } catch (error) {
    console.log(error)
    pageState.error = "Something went wrong on our end"
  } finally {
    pageState.isLoadshapeLoading = false
  }
}

watch(
  () => [props.assetIds, props.orderIds],
  async ([assetIds, orderIds], [oldAssetIds, oldOrderIds]) => {
    if (isEqual(assetIds, oldAssetIds) && isEqual(orderIds, oldOrderIds)) {
      pageState.isLoadshapeLoading = false
      return
    }
    pageState.isLoadshapeLoading = true
    await fetchGraphData(assetIds)
    updateGraphDataMonthly()
    updateGraphDataRolling()
    pageState.isLoadshapeLoading = false
  },
  { deep: true }
)
</script>
