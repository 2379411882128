<template>
  <div class="flex flex-wrap gap-2 p-3">
    <WcDropdown v-model="filterAssetPortfolio" class="basis-40" name="filterAssetPortfolio" :options="portfolioOptions" inset-label="Portfolio" />
  </div>
  <div v-if="!isLoading" class="flex grow flex-col overflow-scroll">
    <h3>{{ assetsData.length }} assets</h3>
    <WcTable
      :data="assetsData"
      :columns="[
        { key: 'id', label: 'ID', align: 'left' },
        { key: 'portfolioId', label: 'PID', align: 'left' },
        { key: 'auditStatus', label: 'Audit Status', align: 'left' },
        { key: 'count', label: 'Total #', align: 'left' },
        { key: 'infoCount', label: '# of Informational', align: 'left' },
        { key: 'warningCount', label: '# of Warnings', align: 'left' },
        { key: 'errorCount', label: '# of Errors', align: 'left' },
        { key: 'needsAcceptanceCount', label: '# Needs Review', align: 'left' },
        { key: 'savingsCount', label: 'Savings', align: 'left' },
        { key: 'savingsNetGco2eAvoided', label: 'Net Avoided CO2e (Savings)', align: 'left' },
        { key: 'eacsNetGco2eAvoided', label: 'Net Avoided CO2e (EACs)', align: 'left' },
        { key: 'latestSavingsDatetime', label: 'Last Savings', align: 'left' },
        { key: 'eacCount', label: '# of EACs', align: 'left' },
        { key: 'latestEacDatetime', label: 'Last EAC', align: 'left' },
      ]"
      row-id-field="id"
      table-class="text-body-2"
      td-class="px-3 py-2 align-top"
      tr-class="even:bg-highlight-pastelazure cursor-pointer hover:bg-highlight-frostblue transition-colors"
      :sortable="true"
      @row-clicked="handleRowClicked">
    </WcTable>
  </div>
  <div v-if="isLoading">Loading...<WcLoadingSpinner class="h-8 w-16" /></div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import type { AssetStatus, AssetAuditStatus } from "@/models/asset"
import type { SelectOption } from "@/components/input/WcDropdown"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import WcDropdown from "@/components/input/WcDropdown.vue"
import { DeviceAuditSummary } from "@/models/audit"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useAssetService } from "@/services/service-container"

const isLoading = ref<boolean>(false)
const assetService = useAssetService()
const router = useRouter()

type AssetRow = {
  id: number
  portfolioId: string | null
  count: number
  infoCount: number
  warningCount: number
  errorCount: number
  needsAcceptanceCount: number
  eacCount: number
  latestEacDatetime: Date
  eacsNetGco2eAvoided: number
  savingsCount: number
  latestSavingsDatetime: Date
  savingsNetGco2eAvoided: number
  status: AssetStatus
  auditStatus: AssetAuditStatus
}

const props = defineProps<{ accountId: number }>()

const filterAssetPortfolio = ref<SelectOption<string> | null>(null)
const portfolioOptions = ref<SelectOption<string | null>[]>([])

const assetAuditSummaries = ref<DeviceAuditSummary[]>([])

const loadAssets = async () => {
  isLoading.value = true
  assetAuditSummaries.value = await assetService.getAssetsAuditSummaries(props.accountId)
  isLoading.value = false

  portfolioOptions.value = [...new Set(assetAuditSummaries.value.map((summary) => summary.portfolioId))].map((id) => ({
    label: id || "None",
    value: id,
  }))
}

onMounted(async () => {
  loadAssets()
})

const assetsData = computed<AssetRow[]>(() => {
  const filteredAssets = assetAuditSummaries.value.filter((asset) => {
    return !filterAssetPortfolio.value || asset.portfolioId === filterAssetPortfolio.value.value
  })
  return filteredAssets.map((asset) => {
    return {
      id: asset.id,
      portfolioId: asset.portfolioId,
      count: asset.count,
      infoCount: asset.infoCount,
      warningCount: asset.warningCount,
      errorCount: asset.errorCount,
      needsAcceptanceCount: asset.needsAcceptanceCount,
      eacCount: asset.eacCount,
      latestEacDatetime: asset.latestEacDatetime,
      savingsCount: asset.savingsCount,
      latestSavingsDatetime: asset.latestSavingsDatetime,
      eacsNetGco2eAvoided: asset.eacsNetGco2eEmitted * -1,
      savingsNetGco2eAvoided: asset.savingsNetGco2eEmitted * -1,
      status: asset.status,
      auditStatus: asset.auditStatus,
    }
  })
})

const handleRowClicked = (row: AssetRow, event: MouseEvent | KeyboardEvent) => {
  const path = `/admin/assets/${row.id}/audits`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}
</script>

<style lang="scss">
.section-panel {
  @apply sticky top-4 flex h-[calc(100vh-8rem)] flex-col overflow-scroll rounded-md bg-white shadow-md;
}
.shadow-top {
  box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 0.1);
}
</style>
