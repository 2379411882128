import { Loadshape, LoadshapeGroupedSummary, LoadshapeSummary } from "@/models/loadshape"
import ApiFetcher from "../api-fetcher"
import { LoadshapeQueryRequest, LoadshapeSummaryQueryRequest } from "@/models/loadshapeQueryRequest"
import { useQueryStore } from "@/store/query.state"

export default class QueryService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  private async get(endpoint: string, request: LoadshapeQueryRequest) {
    try {
      const result = await this.fetcher.httpPost<Loadshape>(endpoint, request)
      const loadshape = Loadshape.GetLoadshape(result)
      return new Promise<Loadshape>((resolve) => {
        resolve(loadshape)
      })
    } catch (error) {
      console.log(error)
      return new Promise<Loadshape>((resolve, reject) => {
        reject(error)
      })
    }
  }
  async getLoadshape(request: LoadshapeQueryRequest): Promise<Loadshape> {
    if (request.sourceIds.length === 0) {
      return Loadshape.GetEmptyLoadshape()
    }

    const queryStore = useQueryStore()

    // limit the query to 100 sourceIds to match API end point
    if (request.sourceIds.length >= 100) {
      request.sourceIds = request.sourceIds.slice(0, 100)
    }

    const queryKey = "loadshape"
    let result = await queryStore.get(request, queryKey)

    if (result) {
      return result
    }
    await queryStore.set(request, Loadshape.GetEmptyLoadshape(), queryKey)
    const endpoint = "/loadshapes/query"

    result = await this.get(endpoint, request)

    //const loadshape = Loadshape.GetLoadshape(result)
    await queryStore.set(request, result, queryKey)
    return result
  }

  async getLoadshapeGroupedSummary(request: LoadshapeSummaryQueryRequest): Promise<LoadshapeGroupedSummary> {
    if (request.sourceIds.length === 0) {
      return new LoadshapeGroupedSummary()
    }

    const queryStore = useQueryStore()
    // limit the query to 100 sourceIds to match API end point
    if (request.sourceIds.length >= 100) {
      request.sourceIds = request.sourceIds.slice(0, 100)
    }
    const queryKey = "total_get"
    let result = await queryStore.get(request, queryKey)
    if (result) {
      return result
    }

    const endpoint = "/loadshapes/summary/get"
    result = await this.fetcher.httpPost<LoadshapeGroupedSummary>(endpoint, request)
    const summary = LoadshapeGroupedSummary.Get(result)
    await queryStore.set(request, summary, queryKey)
    return summary
  }

  async listLoadshapeSummaries(request: LoadshapeSummaryQueryRequest): Promise<Array<LoadshapeSummary>> {
    if (request.sourceIds.length === 0) {
      return new Array<LoadshapeSummary>()
    }

    const queryStore = useQueryStore()
    // limit the query to 100 sourceIds to match API end point
    if (request.sourceIds.length >= 100) {
      request.sourceIds = request.sourceIds.slice(0, 100)
    }
    const queryKey = "total_list"
    let result = await queryStore.get(request, queryKey)
    if (result) {
      return result
    }

    const endpoint = "/loadshapes/summary/list"
    result = await this.fetcher.httpPost<Array<LoadshapeSummary>>(endpoint, request)
    const summaries = result.map((s: LoadshapeSummary) => LoadshapeSummary.Get(s))
    await queryStore.set(request, summaries, queryKey)
    return summaries
  }

  // Deprecated
  async getLoadshapesInBatches(items: Array<any>, batchSize: number) {
    let position = 0
    let results = new Array<any>()
    while (position < items.length) {
      const itemsForBatch = items.slice(position, position + batchSize)
      results = [...results, ...(await Promise.all(itemsForBatch.map((item) => this.getLoadshape(item))))]
      position += batchSize
    }
    return results
  }
}
