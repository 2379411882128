<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-sagetone">
        <td></td>
        <th class="text-left">Version ID</th>
        <th class="text-left">Time</th>
        <th class="text-left">Changed By</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-sagetone">
      <tr v-if="versions.length == 0">
        <td colspan="3" class="py-2 text-left">
          <p v-if="!isLoading">Error. Please refresh.</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <template v-for="version in versions" :key="version.id">
        <tr :data-version-id="version.id" class="cursor-pointer" @click="toggleExpando">
          <td class="pt-2">
            <button type="button" aria-expanded="false" @click.stop="toggleExpando">
              <Icon icon="mdi:chevron-right" class="size-8" />
            </button>
          </td>
          <td class="py-2">{{ version.id }}</td>
          <td class="py-2">
            <time :datetime="version.createdTime">{{ formatDate(version.createdTime) }}</time>
          </td>
          <td class="py-2">{{ version.user.username }}</td>
        </tr>
        <tr :id="`version-${version.id}-diff`" class="hidden">
          <td colspan="4">
            <AssetMetadata :asset="version.device" class="-mt-8" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>

  <PaginationButtons v-if="pageInfo" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadVersions" />
</template>

<script setup lang="ts">
import type { Asset, AssetVersion } from "@/models/asset"
import type { PageInfo } from "@/services/base-fetcher"
import { watch, ref } from "vue"
import { useAssetService } from "@/services/service-container"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { Icon } from "@iconify/vue"
import AssetMetadata from "@/modules/asset/components/AssetMetadata.vue"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

const props = defineProps<{ asset: Asset }>()

// This emit is not implemented, but defined here in order to follow the
// interface between AdminAssetView and its tabs
defineEmits(["on-asset-update-state"])

const assetService = useAssetService()

const isLoading = ref(false)
const pageInfo = ref<PageInfo>()
const versions = ref<AssetVersion[]>([])

const loadVersions = async (url?: string) => {
  try {
    isLoading.value = true

    const result = await assetService.listAssetVersions({ assetId: props.asset.id, url })
    versions.value = result.data
    pageInfo.value = result.pageInfo
  } finally {
    isLoading.value = false
  }
}

watch(
  () => props.asset,
  async () => {
    loadVersions()
  },
  { immediate: true }
)

const toggleExpando = (e: Event) => {
  if (!e.target) return

  const rowEl = (e.target as HTMLElement).closest("tr")
  if (!rowEl) return

  const buttonEl = rowEl.querySelector("button") as HTMLButtonElement
  const versionId = rowEl.dataset.versionId

  const diffEl = document.getElementById(`version-${versionId}-diff`)
  if (!diffEl) return

  if (buttonEl.getAttribute("aria-expanded") === "true") {
    buttonEl.setAttribute("aria-expanded", "false")
    diffEl.classList.add("hidden")
  } else {
    buttonEl.setAttribute("aria-expanded", "true")
    diffEl.classList.remove("hidden")
  }
}

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short", timeStyle: "medium" })
const formatDate = (date: string) => {
  return formatter.format(parseUTCTimestamp(date))
}
</script>

<style scoped>
button[aria-expanded="true"] svg {
  transform: rotate(90deg);
}
</style>
