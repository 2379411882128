<template>
  <Combobox v-model="address" as="div" @update:model-value="(a: unknown) => emit('set-address', (a as LabeledAddress).value)">
    <div class="relative">
      <ComboboxInput
        class="w-full rounded-sm border bg-white py-2 pl-3 pr-10 sm:text-sm"
        :display-value="(r: unknown) => (r as LabeledAddress)?.label"
        @change="onChange" />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <Icon icon="wc-carbon:chevron-sort" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="results.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
        <ComboboxOption v-for="(result, index) in results" :key="index" v-slot="{ active }" :value="result" as="template">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-highlight-pastelazure text-black' : 'text-black']">
            <span :class="['block truncate']">
              {{ result.label }}
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue"
import { debounce } from "lodash"
import { Icon } from "@iconify/vue"
import { Site } from "@/models/site"
import AddressFetcher from "@/services/address-fetcher"

type Address = Site["address"]
type LabeledAddress = {
  label: string
  value: Partial<Address>
}

const props = defineProps({
  address: { type: String, default: "" },
})
const emit = defineEmits(["set-address"])

const results = ref<LabeledAddress[]>([{ label: props.address, value: {} }])

// eslint-disable-next-line vue/no-dupe-keys
const address = ref(results.value[0])

const addressFetcher = new AddressFetcher()
const listAddresses = async (street: string): Promise<LabeledAddress[]> => {
  const query = { street }
  const addressList = await addressFetcher.listAddressMatches(query)
  return addressList.map((a: Address) => {
    return {
      label: `${a.street} ${a.city} ${a.state}, ${a.postalCode}`,
      value: a,
    }
  })
}

const updateAddresses = (val: string) => {
  if (val.length < 1) {
    return
  }
  listAddresses(val).then((addresses) => {
    results.value = addresses
  })
}

const debounceUpdate = debounce(updateAddresses, 100)

const onChange = (event: any) => {
  debounceUpdate(event.target.value)
}
</script>
