<template>
  <form class="flex flex-col gap-2" @submit.prevent="uploadFile">
    <p>Upload assets attributes in bulk as a CSV file.</p>

    <div class="mt-8 border-t border-neutral-30 pt-8">
      <label>
        Select the asset type and methodology you want to use *
        <WcDropdown v-model="assetKind" class="my-2" name="assetKind" :options="methodologyOptions" grouped inset-label="Methodology" required />
      </label>
    </div>

    <template v-if="assetKind">
      <div>
        <p>You can make a CSV file for your assets in two ways:</p>

        <p class="text-subheading-1 mt-4">Simple (Recommended for uploads of 1&ndash;100 assets)</p>
        <p>
          Download the
          <a :href="templateUrl" class="text-body-1-link" target="_blank"
            ><q>{{ assetKind.label }}</q> CSV template</a
          >. Input your assets' attributes, and export the completed sheet as a CSV file.
        </p>

        <p class="text-subheading-1 mt-4">Advanced (Recommended for uploads of 100+ assets)</p>
        <p>
          Create a CSV file from your database that matches the formatting of the
          <a :href="templateUrl" class="text-body-1-link" target="_blank"
            ><q>{{ assetKind.label }}</q> CSV template</a
          >. Make sure that all column labels and input formats match those in the template.
        </p>
      </div>

      <div class="mt-8 border-t border-neutral-30">
        <label class="my-8">
          Upload the completed CSV file *
          <WcInputFile v-model="uploadedFiles" required name="uploadedFiles" accept=".csv" :max-file-size="MAX_FILE_SIZE" class="mt-2" />
        </label>
        <WcButton
          :icon="isUploading ? 'spinner' : undefined"
          text="Upload"
          type="submit"
          :is-disabled="isUploading || uploadedFiles == null || uploadedFiles?.length === 0 || assetKind == null" />
      </div>
    </template>
  </form>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { AssetKind } from "@/models/asset"
import type { SelectOption } from "@/components/input"
import { WcDropdown, WcInputFile } from "@/components/input"
import { WcButton } from "@/components/button"
import type { AssetUploadResult } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"
import { parseCsvFile } from "@/utils/csv"
import { useAssetStore } from "./asset.state"
import { formatValidationResultsForReview } from "./UploadAssetsView.utils"
import { getEnvironment } from "@/environment"

const { API_BASE_URL } = getEnvironment()

const MAX_FILE_SIZE = 1 * 1024 * 1024 // 1 MB

const assetService = useAssetService()
const assetStore = useAssetStore()

const props = withDefaults(defineProps<{ accountId?: number; isAdminContext?: boolean }>(), { isAdminContext: false })
const emit = defineEmits<{ showAssetReview: [] }>()

const assetKind = ref<SelectOption<AssetKind> | null>(null)
const uploadedFiles = ref<FileList | null>(null)
const isUploading = ref<boolean>(false)

const methodologyOptions = computed(() => {
  return assetStore.methodologyCategories.map((category) => ({
    label: category.name,
    items: category.methodologies.map((m) => ({ label: m.name, value: m.kind })),
  }))
})

const templateUrl = computed(() => {
  return `${API_BASE_URL}/devices/csv/template/${assetKind.value?.value}`
})

const uploadFile = async () => {
  const fileData = uploadedFiles.value?.[0]

  if (!fileData || !assetKind.value) {
    console.error("Account, kind, or file not selected")
    return
  }

  if (props.isAdminContext && !props.accountId) {
    console.error("Account not selected")
    return
  }

  isUploading.value = true
  assetStore.assetSubmissionAccountId = props.accountId
  assetStore.assetSubmissionKind = assetKind.value.value
  assetStore.assetSubmissionFile = fileData
  assetStore.assetSubmissionGlobalErrors = []
  assetStore.assetSubmissionGlobalWarnings = []
  assetStore.assetSubmissionRows = []

  // Parse the CSV file
  let csvRows
  try {
    csvRows = await parseCsvFile(fileData)
  } catch (error) {
    if (error instanceof Error) {
      assetStore.assetSubmissionGlobalErrors = [error.message]
    }
  }

  // Validate the uploaded assets and persist to store
  let validationResults = [] as AssetUploadResult[]
  try {
    const result = props.isAdminContext
      ? await assetService.validateAdminUploadedAssets(props.accountId!, assetKind.value.value, fileData)
      : await assetService.validateUploadedAssets(assetKind.value.value, fileData)
    if (Array.isArray(result)) {
      validationResults = result
    } else {
      assetStore.assetSubmissionGlobalErrors = [result.detail]
    }
  } catch (error) {
    if (error instanceof Error) {
      assetStore.assetSubmissionGlobalErrors = [error.message]
    }
  }

  if (validationResults && csvRows) {
    assetStore.assetSubmissionRows = formatValidationResultsForReview(validationResults, csvRows)
    if (assetStore.assetSubmissionGlobalErrors.length === 0 && assetStore.assetSubmissionRows.length === 0) {
      assetStore.assetSubmissionGlobalErrors = ["No valid assets found in the uploaded file"]
    }
  }

  // Reset inputs
  uploadedFiles.value = null
  isUploading.value = false

  emit("showAssetReview")
}
</script>
