import { defineStore } from "pinia"
import { LoadshapeQueryRequest } from "@/models/loadshapeQueryRequest"

const encodeKey = async (key: string) => {
  const myBuffer: ArrayBuffer = new TextEncoder().encode(key)
  const hashBuffer = await crypto.subtle.digest("SHA-256", myBuffer)
  const hashArray: Uint8Array = new Uint8Array(hashBuffer)
  const hashHex: string = Array.from(hashArray)
    .map((byte: number) => ("00" + byte.toString(16)).slice(-2))
    .join("")

  return hashHex
}
const getQueryKey = async (query: LoadshapeQueryRequest, queryKey: string) => {
  let key = `${queryKey}_${query.sourceIds.join(":")}:-${query.groupBy}`
  if (query.startDate) {
    key += `-${query.startDate}`
  }
  if (query.endDate) {
    key += `-${query.endDate}`
  }

  return await encodeKey(key)
}
export const useQueryStore = defineStore("query", {
  state: () => {
    return {
      queries: {} as { [key: string]: any },
    }
  },
  actions: {
    async set(request: LoadshapeQueryRequest, result: any, queryKey: string) {
      const key = await getQueryKey(request, queryKey)
      this.queries[key] = result
    },
    async get(request: LoadshapeQueryRequest, queryKey: string) {
      const key = await getQueryKey(request, queryKey)
      if (Object.hasOwn(this.queries, key)) {
        return this.queries[key]
      }
    },
  },
})
