import ApiFetcher from "@/services/api-fetcher"
import { Portfolio, PortfolioSupplier, PortfolioAllocation } from "@/models/order"

export default class PortfolioService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }
  listPortfolios = async (): Promise<Array<Portfolio>> => {
    const endpoint = "/portfolios"
    return await this.fetcher.httpGet<Array<Portfolio>>(endpoint, {})
  }
  getPortfolio = async (id: string): Promise<Portfolio> => {
    // can include switch statements for the parameter
    const endpoint = `/portfolios/${id}`
    return await this.fetcher.httpGet<Portfolio>(endpoint, { portfolioId: id })
  }
  listPortfoliosAdmin = async (): Promise<Array<Portfolio>> => {
    const endpoint = "/portfolios/admin"
    return await this.fetcher.httpGet<Array<Portfolio>>(endpoint, {})
  }
  addPortfolio = async (portfolioRequest: Portfolio) => {
    const endpoint = "/portfolios"
    return await this.fetcher.httpPost<Portfolio>(endpoint, portfolioRequest)
  }
  updatePortfolio = async (portfolioRequest: Portfolio) => {
    const endpoint = `/portfolios/${portfolioRequest.id}`
    return await this.fetcher.httpPut<Portfolio>(endpoint, portfolioRequest)
  }
  deletePortfolio = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}`
    return await this.fetcher.httpDelete(endpoint, {})
  }
  attachSupplier = async (id: string, supplierId: number, quantity: number) => {
    const endpoint = `/portfolios/${id}/suppliers/${supplierId}`
    return await this.fetcher.httpPut<PortfolioSupplier>(endpoint, { quantity: quantity })
  }
  detachSupplier = async (id: string, supplierId: number) => {
    const endpoint = `/portfolios/${id}/suppliers/${supplierId}`
    return await this.fetcher.httpDelete<PortfolioSupplier>(endpoint)
  }
  getAllocations = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}/allocations`
    return await this.fetcher.httpGet<PortfolioAllocation[]>(endpoint, {})
  }
}
