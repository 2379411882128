<template>
  <div class="w-full">
    <div class="mb-8">
      <h2 class="text-subheading-1 my-4">Documents</h2>
      <WcTable
        v-if="documents.length > 0"
        :data="documents"
        :columns="[
          { key: 'name', label: 'Document', align: 'left' },
          { key: 'description', label: 'Description', align: 'left' },
          { key: 'type', label: 'Type', align: 'left' },
          { key: 'date', label: 'Date', align: 'left' },
          { key: 'delete', label: '', align: 'right' },
        ]"
        row-id-field="id"
        table-class="w-full mx-auto text-body-3"
        tr-class="cursor-pointer hover:bg-highlight-frostblue transition-colors"
        th-class="text-left"
        td-class="px-3 py-2"
        :sortable="true"
        @row-clicked="handleRowClicked">
        <template #name="{ row }">
          <a class="inline max-w-48 underline">{{ row.name }}</a>
        </template>
        <template #description="{ row }">
          <div class="max-w-48">
            {{ row.description }}
          </div>
        </template>
        <template #type="{ row }">
          {{ ASSET_DOCUMENT_TYPE[row.type as AssetDocumentType] }}
        </template>
        <template #delete="{ row }">
          <WcIconButton size="extra-small" icon="wc-carbon:trash-can" @click="(event) => handleDelete(event, row)" />
        </template>
      </WcTable>
      <InlineLoading v-else-if="isLoading" />
      <div v-else>None</div>
    </div>

    <h2 class="text-subheading-1 my-6">Upload a Document</h2>
    <form class="flex flex-col gap-2" @submit.prevent="uploadDocument">
      <label>
        Document
        <WcInputFile v-model="uploadedFiles" required name="test" :max-file-size="MAX_FILE_SIZE" />
      </label>

      <label>
        Description
        <WcInputText v-model="description" name="description" />
      </label>

      <label>
        Type
        <WcDropdown v-model="type" name="type" required :options="documentTypeOptions" :show-clear="false" />
      </label>

      <label>
        Date
        <WcInputDate v-model="date" name="date" required />
      </label>

      <WcButton text="Upload" type="submit" :icon="isUploading ? 'spinner' : 'wc-carbon:upload'" :is-disabled="isUploading" class="w-fit" />
    </form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useToast } from "vue-toastification"
import type { Asset, AssetDocument } from "@/models/asset"
import { ASSET_DOCUMENT_TYPE, AssetDocumentType } from "@/models/asset"
import { useAssetService } from "@/services/service-container"
import { WcButton, WcIconButton } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { WcInputDate, WcDropdown, WcInputFile, WcInputText } from "@/components/input"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcTable from "@/components/WcTable/WcTable.vue"

const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20 MB
const TODAYS_DATE = new Date().toISOString().split("T")[0]

const documentTypeOptions = Object.entries(ASSET_DOCUMENT_TYPE).map(([value, label]) => ({ label, value })) as SelectOption<AssetDocumentType>[]

const toast = useToast()
const assetService = useAssetService()

const { asset } = defineProps<{ asset: Asset }>()

const documents = ref<AssetDocument[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const isUploading = ref<boolean>(false)
const date = ref<string>(TODAYS_DATE)
const description = ref<string>("")
const type = ref<SelectOption<AssetDocumentType>>(documentTypeOptions[0])
const uploadedFiles = ref<FileList | null>(null)

onMounted(() => {
  loadDocuments()
})

const loadDocuments = async () => {
  isLoading.value = true
  try {
    documents.value = await assetService.listAssetDocuments(asset.id)
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset", error)
  }
  isLoading.value = false
}

const uploadDocument = async () => {
  isUploading.value = true
  try {
    const fileData = uploadedFiles.value?.[0]
    if (fileData) {
      const data = {
        date: date.value,
        description: description.value,
        type: type.value.value,
      }
      const document = await assetService.uploadAssetDocument(asset.id, data, fileData)
      documents.value.push(document)

      // Reset inputs
      uploadedFiles.value = null
      date.value = TODAYS_DATE
      description.value = ""
    }
  } catch (error) {
    hasError.value = true
    console.error("There was an error adding the document", error)
  }
  isUploading.value = false
}

const handleRowClicked = (row: AssetDocument) => {
  window.open(row.downloadUrl, "_blank")
}

const handleDelete = async (event: Event, { id, name }: AssetDocument) => {
  event.stopPropagation()

  if (!confirm(`Are you sure you want to delete document "${name}"`)) {
    return
  }

  try {
    await assetService.deleteAssetDocument(asset.id, id)
    documents.value = documents.value.filter((document) => document.id !== id)
  } catch (error) {
    if (error instanceof Error) {
      toast.error(`There was a problem deleting the document\n${error.message}`)
    }
  }
}
</script>

<style scoped lang="scss">
label span {
  padding: 0;
}
</style>
