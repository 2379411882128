<template>
  <WcModal
    :is-open="store.isAccountSwitcherModalOpen"
    :show-close-button="true"
    class="fullWidthContent"
    header="Select an account"
    @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <h3 v-if="personalAccount" class="text-subheading-1 mb-2 ml-4 mt-8">Personal account</h3>
      <button
        v-if="personalAccount"
        class="text-body-1 relative w-full border-y p-4 text-left"
        :class="
          isCurrentAccount(personalAccount) ? 'bg-highlight-pastelazure border-blue-40 cursor-default' : 'hover:bg-neutral-20 border-neutral-30'
        "
        :data-cy="`account-switcher-button-account-${personalAccount.id}`"
        @click="handleSelectAccount(personalAccount)">
        <div v-if="isCurrentAccount(personalAccount)" class="text-body-2 text-blue-70">You are currently logged in as</div>
        {{ personalAccount?.name }}
      </button>
      <h3 class="text-subheading-1 mb-2 ml-4 mt-8">Organization accounts</h3>
      <div>
        <template v-for="account in organizationAccounts" :key="account.id">
          <button
            class="text-body-1 relative -mt-px w-full border-y p-4 text-left"
            :class="
              isCurrentAccount(account) ? 'bg-highlight-pastelazure border-blue-40 cursor-default z-[1]' : 'hover:bg-neutral-20 border-neutral-30'
            "
            :data-cy="`account-switcher-button-account-${account.id}`"
            @click="handleSelectAccount(account)">
            <div v-if="isCurrentAccount(account)" class="text-body-2 text-blue-70">You are currently logged in as</div>
            {{ account.name }}
          </button>
        </template>
      </div>
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue"
import { useRouter } from "vue-router"
import WcModal from "@/components/WcModal.vue"
import { useMainStore } from "@/store"
import { useAuthService } from "@/services/service-container"
import { Account, AccountType } from "@/models/models"
const store = useMainStore()
const authService = useAuthService()
const router = useRouter()

const personalAccount = computed(() => {
  return store.accounts.find((account) => account.type === AccountType.individual) as Account
})

const organizationAccounts = computed(() => {
  const accounts = store.accounts.filter((account) => account.type === AccountType.organization)
  // Put the current account at the top of the list
  const currentAccountIndex = accounts.findIndex((account) => account.id === currentAccountId.value)
  if (currentAccountIndex > -1) {
    const currentAccount = accounts.splice(currentAccountIndex, 1)
    accounts.unshift(currentAccount[0])
  }
  return accounts
})

const currentAccountId = computed(() => {
  return store.auth.user?.account_id
})

const isCurrentAccount = (account: Account) => {
  return account?.id === currentAccountId.value
}

const handleSelectAccount = async (account: Account) => {
  if (!isCurrentAccount(account)) {
    await authService.switchAccount(account.id)
    // Save to local storage to show a toast message after the page reloads
    localStorage.setItem("accountSwitched", "true")
    router.go(0)
  }
  store.isAccountSwitcherModalOpen = false
}

const handleUpdateIsOpen = (isOpen: boolean) => {
  store.isAccountSwitcherModalOpen = isOpen
}

onMounted(() => {
  // If local storage contains a key indicating that the account was switched, show a toast message
  if (localStorage.getItem("accountSwitched")) {
    authService.createAccountSwitchToast()
    localStorage.removeItem("accountSwitched")
  }
})
</script>

<style lang="scss">
.fullWidthContent {
  .p-dialog-header {
    @apply p-4 #{!important};
  }
  .p-dialog-content {
    @apply p-0 #{!important};
  }
}
</style>
