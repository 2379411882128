<template>
  <div>
    <WcTable
      v-if="orderRows.length > 0"
      :data="orderRows"
      :columns="[
        { key: 'date', label: 'Date', align: 'left' },
        { key: 'id', label: 'Order ID', align: 'left' },
        { key: 'account', label: 'Account', align: 'left' },
        { key: 'purchasedAmount', label: 'Purchased Amount', align: 'left' },
        { key: 'totalPaid', label: 'Total Paid', align: 'left' },
      ]"
      row-id-field="id"
      table-class="text-body-2 w-main-content mx-auto"
      th-class="py-2"
      td-class="p-3">
      <!-- Where necessary, to protect sorting functionality, we give the raw data to the table, and handle formatting in scoped slots -->
      <template #date="{ row }">
        {{ format(new Date(row.date), "LLLL d, y") }}
      </template>
      <template #id="{ row }">
        <span data-cy="orders-table-row">{{ row.id }}</span>
      </template>
      <template #purchasedAmount="{ row }"> {{ getFormattedEacQuantity(row.purchasedAmount, row.units) }}</template>
      <template #totalPaid="{ row }">
        {{ (row.totalPaid / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) }}
      </template>
    </WcTable>
    <div v-else class="wc-page-container">
      <div class="flex flex-col items-center justify-center gap-8 bg-neutral-20 p-8">
        <p class="text-body-2 text-center">You have not placed any orders.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Components
import WcTable from "@/components/WcTable/WcTable.vue"

// Libraries
import { format } from "date-fns"
import { getFormattedEacQuantity, PORTFOLIO_MEASUREMENT_PARAMETERS } from "@/models/order"

// Services
import { useOrdersService } from "@/services/service-container"
import { Order } from "@/models/order"

const ordersService = useOrdersService()

const props = defineProps<{
  portfolioId: string
}>()

let orders = await ordersService.listOrders()
orders = orders.sort((a: Order, b: Order) => new Date(b.updatedTime).getTime() - new Date(a.updatedTime).getTime())
const orderRows = orders
  .filter((o) => o.portfolio.id == props.portfolioId)
  .map((order) => ({
    date: order.updatedTime,
    id: order.id,
    account: order.account.name,
    portfolio: order.portfolio.name,
    purchasedAmount: order.quantity,
    totalPaid: order.pricePenniesUsd,

    // this does not have a column in the table, but it's provided for formatting
    units: PORTFOLIO_MEASUREMENT_PARAMETERS[order.portfolio.units],
  }))
</script>
