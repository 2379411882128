<template>
  <AppPage class="w-full">
    <section class="bg-background-sagelight">
      <AppPageHeader
        :backlink-route="asset?.group?.id ? { name: 'wc-asset-groups-asset-group', params: { assetGroupId: asset.group.id } } : { name: 'wc-assets' }"
        :backlink-text="asset?.group?.name ? `Back to ${asset.group.name}` : 'Back to Assets'"
        show-account-context>
        <span class="text-subheading-large-bold"> {{ asset ? getAssetDisplayName(asset) : "" }}</span>
        <template #title-adjacent>
          <div class="flex flex-col items-end">
            <div v-if="asset" class="flex rounded bg-neutral-10 px-6 py-4">
              <AssetStatusIcon :status="asset.status" class="mt-0.5" />
              <div class="text-subheading-2">
                {{ ASSET_STATUS[asset.status]?.summary }}
              </div>
            </div>
            <div v-if="asset && asset.status === AssetStatus.changes_requested" class="text-body-2 mt-6">
              {{ reviewNotesSummary }}
              <button v-if="isReviewNotesTruncated" class="text-body-2-link inline pl-1" @click="changesRequestedModalVisible = true">
                read more
              </button>
            </div>
          </div>
        </template>
      </AppPageHeader>
      <div class="wc-page-container grid grid-cols-2 pb-16 lg:grid-cols-4">
        <div class="col-span-1 lg:col-span-3">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Total EACs</h3>
          </div>
          <div>
            <div class="mb-1">
              <span class="text-featured-number pr-2" data-cy="total-retired"> {{ netEacs.quantity }} </span>
              <span> {{ netEacs.unit }} </span>
            </div>
            <div v-if="Number(netElectricityEacs.quantity) > 0 || Number(netCarbonEacs.quantity) > 0" class="text-body-3">
              {{ netElectricityEacs.quantity }} {{ netElectricityEacs.unit }} + {{ netCarbonEacs.quantity }} {{ netCarbonEacs.unit }}
            </div>
          </div>
        </div>
      </div>
      <WcTabs>
        <WcTab :is-active="selectedTab === Tab.Details" @click="selectedTab = Tab.Details">Details</WcTab>
        <WcTab :is-active="selectedTab === Tab.EACProduction" @click="selectedTab = Tab.EACProduction">EAC Production</WcTab>
      </WcTabs>
    </section>
    <section class="wc-page-container mb-24 w-full">
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasError" error-message="There was a problem loading this asset. Please try again." />

      <div v-if="selectedTab === Tab.Details">
        <AssetMetadata v-if="asset && !isLoading && !hasError" :asset="asset" :excluded-fields="['status', 'statusDetail']" class="mt-12" />
        <AssetDocuments v-if="documents && !isLoading && !hasError" :documents="documents" class="mt-12" />
        <WcButton v-if="allowUploadAssetTimeSeries" class="mt-12" text="Upload time series data" @click="handleUploadTimeSeriesData" />
      </div>
      <div v-else-if="selectedTab === Tab.EACProduction" class="pt-8">
        <AssetLoadshapeView v-if="asset" :asset="asset" :summary="assetSummary" />
      </div>
    </section>
  </AppPage>
  <WcModal :is-open="changesRequestedModalVisible" header="Changes Requested" @update:is-open="(isOpen) => (changesRequestedModalVisible = isOpen)">
    <template #panel>
      {{ asset!.reviewNotes }}
    </template>
    <template #footer>
      <WcButton text="Close" class="ml-auto" size="small" @click="changesRequestedModalVisible = false" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import type { Asset, AssetDocument } from "@/models/asset"
import { ASSET_STATUS, AssetStatus, getAssetDisplayName } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import { WcButton } from "@/components/button"
import { AppPage, AppPageHeader, WcTab, WcTabs } from "@/components/layout"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcModal from "@/components/WcModal.vue"
import type { AssetSummary } from "@/modules/asset/asset.service"
import { netWhFromValues, netGco2eFromValues, netEacsFromValues } from "@/modules/registry/registry.utils"
import { useAssetService } from "@/services/service-container"
import AssetStatusIcon from "./components/AssetStatusIcon.vue"
import AssetLoadshapeView from "./components/AssetLoadshapeView.vue"
import AssetMetadata from "./components/AssetMetadata.vue"
import AssetDocuments from "./components/AssetDocuments.vue"
import { useAllowUploadAssetTimeSeries } from "@/features"

const MAX_REVIEW_NOTES_LENGTH = 40

enum Tab {
  Details = "details",
  EACProduction = "eacProduction",
}

const allowUploadAssetTimeSeries = useAllowUploadAssetTimeSeries()
const assetService = useAssetService()
const router = useRouter()

const props = defineProps<{ assetId: number }>()

const asset = ref<Asset | undefined>()
const assetSummary = ref<AssetSummary | undefined>()
const documents = ref<AssetDocument[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const changesRequestedModalVisible = ref<boolean>(false)
const selectedTab = ref<Tab>(Tab.Details)

const isReviewNotesTruncated = computed(() => {
  return (asset.value?.reviewNotes || "").length > MAX_REVIEW_NOTES_LENGTH
})
const reviewNotesSummary = computed(() => {
  const reviewNotes = asset.value?.reviewNotes
  if (isReviewNotesTruncated.value) {
    return reviewNotes!.slice(0, MAX_REVIEW_NOTES_LENGTH) + "..."
  } else {
    return reviewNotes
  }
})

onMounted(async () => {
  try {
    asset.value = await assetService.getAsset(props.assetId)
    documents.value = await assetService.listAssetDocuments(props.assetId)
    assetSummary.value = await assetService.getAssetSummary(props.assetId)
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset", error)
  }
  isLoading.value = false
})

const eacSummaryEntries = computed(() => {
  if (!assetSummary.value?.eacs.sum && !assetSummary.value?.preliminaryEacs.sum) {
    return []
  }
  return assetSummary.value.eacs.sum
})

const netEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "0", unit: "" }
  const netEacs = netEacsFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledLong)
})

const netElectricityEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "", unit: "" }
  const netElectricity = netWhFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netElectricity, EacMeasurementParameter.Electricity)
})

const netCarbonEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "", unit: "" }
  const netCarbon = netGco2eFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netCarbon, EacMeasurementParameter.GhgEmissions)
})

const handleUploadTimeSeriesData = () => {
  if (asset.value) {
    router.push({ name: "wc-assets-asset-timeseries-upload", params: { assetId: props.assetId } })
  }
}
</script>
