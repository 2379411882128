<template>
  <AppPage>
    <section class="bg-background-sagelight">
      <AppPageHeader page-type-label="Portfolio" show-account-context backlink-text="Back to Dashboard" :backlink-route="{ name: 'wc-dashboard' }">
        <span class="text-subheading-large-bold text-wrap pt-3.5">{{ orderDetail?.orderSummary?.portfolio.name }}</span>
      </AppPageHeader>
      <div class="wc-page-container grid grid-cols-2 pb-16 lg:grid-cols-4">
        <div class="col-span-1">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Status</h3>
          </div>
          <div class="capitalize">{{ orderDetail?.orderSummary.portfolio.status }}</div>
        </div>
      </div>
      <WcTabs>
        <WcTab :is-active="selectedTab === Tab.Orders" @click="selectedTab = Tab.Orders">Orders</WcTab>
        <WcTab :is-active="selectedTab === Tab.EACs" @click="selectedTab = Tab.EACs">EACs</WcTab>
        <WcTab :is-active="selectedTab === Tab.Assets" @click="selectedTab = Tab.Assets">Assets</WcTab>
      </WcTabs>
    </section>
    <section v-if="selectedTab === Tab.Orders" class="wc-page-container mt-8">
      <AccountsOrdersTable :portfolio-id="portfolioId" class="overflow-x-scroll" />
    </section>
    <section v-if="selectedTab === Tab.EACs" class="w-full py-10">
      <div class="wc-page-container">
        <h3 class="text-subheading-large-bold">Energy Attribute Certificates</h3>
        <div class="wc-homepage-grid mt-6 md:mt-8">
          <div class="col-span-5 md:mb-3">
            <p>
              Figures shown on this page reflect Energy Attribute Certificates (EACs) allocated to you based on your contribution to the portfolio.
            </p>
          </div>
          <div v-if="orderDetail?.orderSummary.quantityTotal !== null" class="col-span-3 mt-1 md:col-start-7">
            <h4 class="text-overline-1">Purchased Amount</h4>
            <div class="text-body-1 mt-3">
              <span data-cy="purchased-amount-number">
                {{ totalOrdered.quantity }}
              </span>
              {{ totalOrdered.unit }}
            </div>
          </div>
          <div class="col-span-3 mt-1">
            <h4 class="text-overline-1">Allocated Amount</h4>
            <div class="text-body-1 mt-3">{{ totalAllocatedShorthand }}</div>
          </div>
        </div>
        <div class="ph-no-capture mt-8 flex w-full flex-col gap-2 sm:flex-row">
          <WcButton class="items-stretch" text="View Certificate" variant="secondary" @click="isShareableEACVisible = true" />
        </div>
        <ShareableEAC
          v-if="isShareableEACVisible"
          ref="shareableEAC"
          :account-name="orderDetail?.orderSummary?.accountName"
          :total-amount="totalAllocated || 0"
          :carbon-value="orderDetail?.orderSummary?.carbonValueG || 0"
          :portfolio-name="orderDetail?.orderSummary?.portfolio.name"
          :resource-type="overallAssetKind"
          :start-date="''"
          :updated-time="format(parseISO(orderDetail?.orderSummary?.updatedTime || new Date().toISOString()), 'LLLL d, y')"
          :locations="allAssetStates"
          :units="orderDetail.orderSummary.portfolio.units"
          :balancing-authority="''"
          @close="isShareableEACVisible = false"
          @clicked-review-eacs="handleClickReviewEacs()" />
      </div>
    </section>

    <section v-if="selectedTab === Tab.Assets" class="wc-page-container">
      <div v-if="assetsByStoryId.size">
        <div
          v-for="[storyId, assetList] in assetsByStoryId"
          :key="storyId"
          class="wc-homepage-grid mt-10 border-neutral-30 pb-6 [&:not(:last-child)]:border-b">
          <div class="col-span-4">
            <template v-if="stories.get(storyId).status === 'published'">
              <a :href="`${WWW_BASE_URL}/stories/${storyId}`" target="_blank">
                <h4 class="text-subheading-1">{{ stories.get(storyId).name }}</h4>
              </a>
            </template>
            <template v-else>
              <h4 class="text-subheading-1">{{ stories.get(storyId).name }}</h4>
            </template>
          </div>
          <div class="col-span-8">
            <div class="w-full overflow-x-scroll px-4 md:px-[50px]">
              <div role="table" class="table w-full min-w-[540px]">
                <div role="rowgroup" class="table-header-group">
                  <div role="row" class="text-overline-1 table-row">
                    <div role="columnheader" class="table-cell border-b border-neutral-30 pb-[14px] text-center">Asset ID</div>
                    <div role="columnheader" class="table-cell border-b border-neutral-30 pb-[14px] text-center">Location</div>
                    <div role="columnheader" class="table-cell border-b border-neutral-30 pb-[14px] text-center">Coordinates</div>
                    <div role="columnheader" class="table-cell border-b border-neutral-30 pb-[14px] text-center">Allocated Amount</div>
                  </div>
                </div>
                <div role="rowgroup" class="table-row-group">
                  <div v-for="asset in assetList" :key="asset.id" role="row" class="table-row">
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">{{ asset.id }}</div>
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">{{ asset.location }}</div>
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">
                      {{ asset.coordinates.latitude }}°, {{ asset.coordinates.longitude }}°
                    </div>
                    <div role="cell" class="table-cell w-1/4 py-4 text-center">
                      {{ getFormattedEacQuantity(asset.allocation?.eacsAllocated || 0, measurementParameter) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-10">
        <div class="flex flex-col items-center justify-center gap-8 bg-neutral-20 p-8">
          <p class="text-body-2 text-center">Assets in this portfolio have not been registered yet.</p>
        </div>
      </div>
    </section>
  </AppPage>
</template>

<script setup lang="ts">
// Libraries
import { computed, ref, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import { format, parseISO } from "date-fns"
import posthog from "posthog-js"
import { getEnvironment } from "@/environment"

// Components
import { WcButton } from "@/components/button"
import { AppPage, AppPageHeader, WcTab, WcTabs } from "@/components/layout"
import ShareableEAC from "@/modules/accounts/components/ShareableEAC.vue"
import AccountsOrdersTable from "./AccountsOrdersTable.vue"
import { useAssetStore } from "@/modules/asset/asset.state"

// Models
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"

// Services
import { useOrdersService } from "@/services/service-container"

enum Tab {
  Orders = "orders",
  EACs = "EACs",
  Assets = "assets",
}

const { WWW_BASE_URL } = getEnvironment()

const props = defineProps<{
  portfolioId: string
}>()

const route = useRoute()
const router = useRouter()
const ordersService = useOrdersService()
const assetStore = useAssetStore()

// PostHog Events
const captureReviewEacsEvent = () => posthog.capture("Clicked on the 'Review & Retire EACs' button - Accounts Portfolio View")

const selectedTab = ref<Tab>(Tab.Orders)
const orderDetail = await ordersService.getOrderDetail(props.portfolioId)

const stories = computed(() => {
  const stories = new Map()
  orderDetail.devices.forEach((asset) => {
    stories.set(asset.story.id, asset.story)
  })
  return stories
})

const assetsByStoryId = computed(() => {
  const assetsByStoryId = new Map()
  orderDetail.devices.forEach((asset) => {
    const storyId = asset.story.id
    const assetList = assetsByStoryId.get(storyId)
    if (assetList) {
      assetList.push(asset)
    } else {
      assetsByStoryId.set(storyId, [asset])
    }
  })
  return assetsByStoryId
})

const measurementParameter = computed(() => {
  return orderDetail.orderSummary.portfolio.units === "wh_electricity" ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
})

const totalOrdered = computed(() => {
  const quantityTotal = orderDetail?.orderSummary.quantityTotal
  return getFormattedEacQuantity(quantityTotal ?? 0, measurementParameter.value)
})

const totalAllocatedShorthand = computed(() => {
  const eacsAllocated = orderDetail?.orderSummary.eacsAllocated
  return getFormattedEacQuantity(eacsAllocated || 0, measurementParameter.value)
})

const totalAllocated = computed(() => {
  return orderDetail?.orderSummary.eacsAllocated
})

const handleClickReviewEacs = () => {
  captureReviewEacsEvent()
  router.push({ name: "wc-eacs-select-eacs" })
}

const shareableEAC = ref(null)
const isShareableEACVisible = ref(false)
onMounted(() => {
  if (route.query.showCertificate) {
    isShareableEACVisible.value = true
  }
})

const allAssetStates = computed(() => {
  const assetStates = new Set(orderDetail.devices.map((a) => a.state))
  return Array.from(assetStates).join(", ")
})

const overallAssetKind = computed(() => {
  const assetKinds = new Set(orderDetail.devices.map((d) => assetStore.formatAssetKind(d.kind)))
  return Array.from(assetKinds).join(", ")
})
</script>
