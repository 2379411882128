import { defineStore } from "pinia"
import { Order } from "@/models/order"
import { ref } from "vue"
import { useOrdersService } from "@/services/service-container"

export const useAdminOrdersStore = defineStore("adminOrders", () => {
  const orders = ref<Array<Order>>([])
  const isLoading = ref<boolean>(true)

  const ordersService = useOrdersService()

  const loadOrders = async () => {
    isLoading.value = true
    try {
      orders.value = await ordersService.listOrdersAdmin()
    } finally {
      isLoading.value = false
    }
  }

  loadOrders()

  return {
    // state
    isLoading,
    orders,
    // actions
    loadOrders,
  }
})
