<template>
  <div class="mt-2 flex items-start gap-4">
    <WcCard header-text="Net Impact" card-class="basis-1/5 h-[320px]" header-class="text-center" main-class="h-[240px]">
      <div class="flex h-full flex-col justify-around">
        <div class="mt-5 text-center" data-cy="net-impact-emissions">
          <WcStat
            title="Net Emissions"
            :figure="formatNumericalFigure(netCarbonEmissions)"
            figure-class="text-4xl"
            unit="tCO2"
            unit-class="text-2xl"
            :marginal-stats="[
              {
                text: `${formatNumericalFigure(netCarbonRatio, { isNegativeZero: false, precision: 2 })}`,
                unit: 'tCO2perMWh',
                class: 'text-sagetone',
              },
            ]"></WcStat>
        </div>
        <div class="mb-5 text-center" data-cy="net-impact-energy">
          <WcStat
            title="Net Carbon-based"
            subtitle="Energy Consumption"
            :figure="formatNumericalFigure(netCarbonBasedEnergyConsumption)"
            figure-class="text-4xl"
            unit="MWh"
            unit-class="text-2xl"
            :marginal-stats="[
              {
                text: `${formatNumericalFigure((netCarbonBasedEnergyConsumption / locationalTotalRow.energySumMwh) * 100 || 0, {
                  isNegativeZero: false,
                  precision: 2,
                })}% of gross consumption`,
                class: 'text-sagetone',
              },
            ]"></WcStat>
        </div>
      </div>
    </WcCard>

    <WcCard header-text="Locational Sites" card-class="basis-2/5 h-[320px]" header-class="text-center" main-class="h-[240px]">
      <div class="flex h-full items-stretch">
        <div class="flex basis-1/2 flex-col justify-around">
          <div class="mt-6 text-center">
            <WcStat
              title="Carbon Emissions"
              :figure="formatNumericalFigure(locationalTotalRow.carbonSumTonnesCo2e || 0)"
              unit="tCO2"
              :marginal-stats="[
                {
                  text: `${formatNumericalFigure(locationalTotalRow.carbonSumTonnesCo2e / locationalTotalRow.energySumMwh || 0, {
                    isNegativeZero: false,
                    precision: 2,
                  })}`,
                  unit: 'tCO2perMWh',
                  class: 'text-sagetone',
                },
              ]"></WcStat>
          </div>
          <div class="mb-6 text-center">
            <WcStat
              title="Carbon-based"
              subtitle="Energy Consumption"
              :figure="formatNumericalFigure(locationalCarbonBasedEnergyConsumption)"
              unit="MWh"
              :marginal-stats="[
                {
                  text: `${formatNumericalFigure((locationalCarbonBasedEnergyConsumption / locationalTotalRow.energySumMwh) * 100 || 0, {
                    isNegativeZero: false,
                    precision: 2,
                  })}% of gross consumption`,
                  class: 'text-sagetone',
                },
              ]"></WcStat>
          </div>
        </div>
        <div class="basis-1/2 border-l border-neutral-30">
          <ul v-if="siteRows.length" class="h-full overflow-y-scroll" data-cy="locational-assets-list">
            <li v-for="r in siteRows" :key="r.id" class="border-b border-neutral-30 p-2">
              <div class="flex w-full items-end justify-between">
                <div>
                  <div class="text-sm">{{ r.name }}</div>
                  <div class="text-xs">{{ r.site.address.city }}, {{ r.site.address.state }}</div>
                </div>
                <div class="shrink-0 text-right text-sagetone">
                  <div class="text-xs">{{ formatNumericalFigure(r.carbonSumTonnesCo2e) }} tCO<sub>2</sub></div>
                  <div class="text-xs" data-cy="site-total-energy">{{ formatNumericalFigure(r.energySumMwh) }} MWh</div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="flex h-full flex-col justify-between bg-neutral-20 p-4">
            <div>
              <p>You haven’t added any sites yet.</p>
              <p class="mt-4">
                Add sites in <RouterLink class="underline" to="/load">Load</RouterLink> to start tracking their energy and emissions.
              </p>
            </div>
            <div>
              <RouterLink to="/load" class="mt-2 inline-block">
                <button class="rounded-md border border-neutral-30 bg-white px-3 py-2 text-base font-normal hover:bg-neutral-20">Manage sites</button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </WcCard>

    <WcCard header-text="Market Sites" card-class="basis-2/5 h-[320px]" header-class="text-center" main-class="h-[240px]">
      <div class="flex h-full items-stretch">
        <div class="flex basis-1/2 flex-col justify-around">
          <div class="mt-6 text-center">
            <WcStat
              title="Carbon Avoided"
              :figure="formatNumericalFigure(marketTotalRow.carbonSumTonnesCo2e || 0, { isNegativeZero: true, precision: 3 })"
              unit="tCO2"
              :marginal-stats="[
                {
                  text: `${formatNumericalFigure((-1 * marketTotalRow.carbonSumTonnesCo2e) / marketTotalRow.energySumMwh || 0, {
                    isNegativeZero: true,
                    precision: 2,
                  })}`,
                  unit: 'tCO2perMWh',
                  class: 'text-sagetone',
                },
              ]"></WcStat>
          </div>
          <div class="mb-6 text-center">
            <WcStat
              title="Carbon-free"
              subtitle="Energy Production"
              :figure="formatNumericalFigure(marketTotalRow.energySumMwh || 0, { isNegativeZero: true, precision: 3 })"
              unit="MWh"
              :marginal-stats="[]"></WcStat>
          </div>
        </div>
        <div class="basis-1/2 border-l border-neutral-30">
          <!-- ToDo: Refactor into a WcAssetItem component -->
          <ul v-if="marketAssetRows.length" class="h-full overflow-y-scroll">
            <li v-for="r in marketAssetRows" :key="r.id" class="border-b border-neutral-30 p-2">
              <div class="flex w-full items-end justify-between">
                <div>
                  <div class="text-sm">{{ r.name }}</div>
                  <div v-if="r.order" class="-mt-0.25 block text-xs">
                    <span class="rounded bg-neutral-30 px-1 py-0.5 text-white">{{ r.order.status }}</span>
                  </div>
                  <div v-if="r.asset" class="text-xs">{{ getMarketAssetTypeLabel(r.asset.type) }}</div>
                </div>
                <div class="shrink-0 text-right text-sagetone">
                  <div class="text-xs">
                    {{ formatNumericalFigure(r.carbonSumTonnesCo2e || 0, { isNegativeZero: true, precision: 3 }) }} tCO<sub>2</sub>
                  </div>
                  <div class="text-xs" data-cy="site-total-energy">
                    {{ formatNumericalFigure(r.energySumMwh || 0, { isNegativeZero: true, precision: 3 }) }} MWh
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="h-full bg-neutral-20 p-4">
            <p>You haven’t procured any decarbonization assets.</p>
          </div>
        </div>
      </div>
    </WcCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, PropType } from "vue"
// Components
import WcStat from "@/components/WcStat.vue"
import WcCard from "../../../components/WcCard.vue"
import { formatNumericalFigure, getMarketAssetTypeLabel } from "../carbonAccountingDashboard.helper"

const props = defineProps({
  siteRows: { type: Array as PropType<Array<any>>, default: new Array<any>(), required: true },
  marketAssetRows: { type: Array as PropType<Array<any>>, default: new Array<any>(), required: true },
})

const locationalTotalRow = ref({ carbonBasedPercent: 0, energySumMwh: 0, carbonSumTonnesCo2e: 0 })
const marketTotalRow = ref({ carbonSumTonnesCo2e: 0, energySumMwh: 0 })
watch(
  () => props.siteRows,
  (rows) => {
    const carbonSumTonnesCo2e = rows.reduce((sum, current) => sum + current.carbonSumTonnesCo2e, 0)
    const energySumMwh = rows.reduce((sum, current) => sum + current.energySumMwh, 0)
    const carbonBasedEnergySumMwh = rows.reduce((sum, current) => sum + current.locationalCarbonBasedEnergySumMwh, 0)
    const carbonBasedPercent = (carbonBasedEnergySumMwh / energySumMwh) * 100
    locationalTotalRow.value = { carbonSumTonnesCo2e, energySumMwh, carbonBasedPercent }
  }
)
watch(
  () => props.marketAssetRows,
  (rows) => {
    const carbonSumTonnesCo2e = rows.reduce((sum, current) => sum + (current.carbonSumTonnesCo2e || 0), 0)
    const energySumMwh = rows.reduce((sum, current) => sum + (current.energySumMwh || 0), 0)
    marketTotalRow.value = { carbonSumTonnesCo2e, energySumMwh }
  }
)
const locationalCarbonBasedEnergyConsumption = computed(() => {
  return (locationalTotalRow.value.carbonBasedPercent / 100) * locationalTotalRow.value.energySumMwh || 0
})
const netCarbonBasedEnergyConsumption = computed(() => {
  return (locationalTotalRow.value.carbonBasedPercent / 100) * locationalTotalRow.value.energySumMwh + marketTotalRow.value.energySumMwh || 0
})
const netCarbonEmissions = computed(() => {
  return locationalTotalRow.value.carbonSumTonnesCo2e + marketTotalRow.value.carbonSumTonnesCo2e || 0
})
const netCarbonRatio = computed(() => {
  return (
    Math.max(
      (locationalTotalRow.value.carbonSumTonnesCo2e + marketTotalRow.value.carbonSumTonnesCo2e) /
        (locationalTotalRow.value.energySumMwh + marketTotalRow.value.energySumMwh),
      0
    ) || 0
  )
})
</script>
