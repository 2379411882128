<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AppPageSection>
    <AppPageSectionHeader>Transactions</AppPageSectionHeader>
    <div id="transactions" class="overflow-x-scroll">
      <AccountTransactionsTable :kind="INCLUDED_KINDS" />
    </div>
  </AppPageSection>
</template>

<script setup lang="ts">
import { AppPageSection, AppPageSectionHeader } from "@/components/layout"
import AccountTransactionsTable from "@/modules/accounts/components/AccountTransactionsTable.vue"
import type { TransactionKind } from "@/services/api/transaction.service"

const INCLUDED_KINDS: TransactionKind[] = ["listing_sale", "purchase"]
</script>
