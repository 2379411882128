<template>
  <template v-if="portfolio.suppliers !== undefined">
    <ul class="divide-y divide-neutral-30">
      <li v-for="supplier in portfolio.suppliers" :key="supplier.id" class="flex items-center justify-between py-2">
        <div>
          {{ supplier.name }}
        </div>
        <div class="flex gap-4">
          <button class="text-error disabled:cursor-not-allowed disabled:text-sagetone" @click="removeSupplier(supplier)">
            <Icon icon="wc-carbon:close-outline" />
            Remove
          </button>
        </div>
      </li>
      <li v-if="portfolio.suppliers.length == 0" class="py-2">No suppliers.</li>
      <li class="py-2">
        <button
          class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone"
          data-cy="spoof-button"
          @click="isAddSupplierDialogOpen = true">
          <Icon icon="wc-carbon:add" />
          Add Supplier
        </button>
      </li>
    </ul>
  </template>

  <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />

  <WcModal :is-open="isAddSupplierDialogOpen" header="Attach Supplier" @update:is-open="(isOpen) => (isAddSupplierDialogOpen = isOpen)">
    <template #panel>
      <form ref="formRef" @submit.prevent="addSupplier">
        <label>
          Supplier
          <select name="supplier" class="p-2" required>
            <option v-for="s in unattachedSuppliers" :key="s.id" :value="s.id">
              {{ s.name }}
            </option>
          </select>
        </label>
      </form>
    </template>
    <template #footer>
      <WcButton text="Cancel" variant="secondary" size="small" @click="isAddSupplierDialogOpen = false" />
      <WcButton text="Attach" size="small" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"
import { WcButton } from "@/components/button"
import { AccountName } from "@/models/models"
import WcModal from "@/components/WcModal.vue"
import _ from "lodash"

const props = defineProps<{
  portfolioId: string
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const adminAccountsStore = useAdminAccountsStore()

const formRef = ref<HTMLFormElement | null>(null)

const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

const isAddSupplierDialogOpen = ref<boolean>(false)
const unattachedSuppliers = computed(() => {
  const allSuppliers = adminAccountsStore.accounts.filter((a) => a.type === "organization")
  const mySuppliers = portfolio.value.suppliers
  const unattached = _.differenceBy(allSuppliers, mySuppliers, (s) => s.id)
  return _.sortBy(unattached, ["name"])
})

const addSupplier = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  const supplier = adminAccountsStore.getAccountById(parseInt(formEl.supplier.value))!
  await adminPortfoliosStore.addSupplier(portfolio.value, supplier)
  isAddSupplierDialogOpen.value = false
  formEl.reset()
}

const removeSupplier = async (supplier: AccountName) => {
  if (confirm(`Remove ${supplier.name} from this portfolio?`)) {
    await adminPortfoliosStore.removeSupplier(portfolio.value, supplier)
  }
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}
</style>
