<template>
  <section class="page disable-select w-full max-w-[400px] px-4">
    <img class="w-[320px]" src="https://static.wattcarbon.com/wattcarbon/logo/wattcarbon_logo_on-light_158px.svg" />
    <div class="text-body-3 disable-select mt-8 flex w-full flex-col items-center justify-center rounded-lg border border-blue-90 bg-white p-4">
      <form class="w-full" @submit.prevent="register(email)">
        <h2 class="text-heading-5 mb-6 mt-4 text-center">Create an account</h2>
        <div v-if="isLoggedIn" class="mb-6 rounded-md bg-highlight-pastelazure p-4">
          <p><strong>We sent you a link to sign in and continue.</strong></p>
          <p class="mt-4">This link will expire in 10 minutes. Each link can only be used once.</p>
        </div>
        <div v-if="errors.length > 0" class="my-4 w-full rounded-lg bg-highlight-yellow p-4">
          <ol>
            <li v-for="e in errors" :key="e">{{ e }}</li>
          </ol>
        </div>
        <TextInput
          v-model="email"
          :disabled="isLoggedIn"
          placeholder="jordan@example.com"
          label="Your email"
          class="ph-no-capture"
          @click="captureEmailInputEvent" />

        <p class="text-body-3 mt-3">
          By creating an account, you are agreeing to be bound by our
          <a :href="`${WWW_BASE_URL}/terms`" class="underline">Terms and Conditions</a>.
        </p>

        <WcButton v-if="!isLoggedIn" class="mt-4 w-full" text="Create Account" type="submit" button-classes="ph-no-capture" />
        <WcButton
          v-else
          class="mt-4 w-full"
          text="Resend link"
          variant="secondary"
          button-classes="ph-no-capture"
          @click.prevent="register(email)"
          @click="captureResendLinkEvent" />
        <div class="mt-8 w-full text-center">
          <router-link class="text-body-2 ph-no-capture text-blue-70 underline" to="/signin" @click="captureSignIntoExistingAccountEvent"
            >Sign in to an existing account</router-link
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onActivated, ref, computed } from "vue"
import posthog from "posthog-js"
import { useMainStore } from "@/store"
import { useAuthStore } from "@/modules/auth/auth.state"
import { useAuthService } from "@/services/service-container"
import { WcButton } from "@/components/button"
import TextInput from "@/components/ui/TextInput.vue"
import { getEnvironment } from "@/environment"

import { push } from "./auth.utils"

const { useWwwAuth = false } = defineProps<{ useWwwAuth?: boolean }>()

const { WWW_BASE_URL } = getEnvironment()

const store = useMainStore()
const authStore = useAuthStore()
if (store.auth.isAuthenticated && store.auth.token) {
  push("dashboard", "/")
}
onActivated(() => {
  if (store.auth.isAuthenticated && store.auth.token) {
    push("dashboard", "/")
  }
})

// PostHog Events
const captureEmailInputEvent = () => posthog.capture('Clicked on "Email" input - Register View')
const captureCreateAccountEvent = () => posthog.capture('Clicked on "Create Account" button - User attempted to create an account - Register View')
const captureSignIntoExistingAccountEvent = () => posthog.capture('Clicked on "Sign into an existing account" link - Register View')
const captureCreateAccountErrorEvent = () => posthog.capture("Error when trying to create an account - Register View")
const captureResendLinkEvent = () => posthog.capture('Clicked on the "Resend Link" button - Register View')

const isLoggedIn = ref(false)
const email = ref("")
const errors = computed(() => authStore.errors)
const authService = useAuthService()
const register = (email: string) => {
  captureCreateAccountEvent()
  authStore.resetErrors()
  authService.register(email, useWwwAuth).then(
    () => {
      isLoggedIn.value = true
    },
    () => {
      authStore.addError("Please enter a valid email.")
      captureCreateAccountErrorEvent()
    }
  )
}
</script>

<style scoped lang="scss">
.login-card {
  width: 400px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
