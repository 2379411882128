<template>
  <form class="flex flex-col items-start gap-2" @submit.prevent="upsertAccount(account)">
    <label>
      Name
      <input v-model="account.name" type="text" required />
    </label>

    <WcButton text="Save" type="submit" icon="wc-carbon:save" />
  </form>
</template>

<script setup lang="ts">
import { computed } from "vue"
import _ from "lodash"
import { WcButton } from "@/components/button"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { Account } from "@/models/models"
import { useRouter } from "vue-router"

const props = defineProps<{
  accountId?: number
}>()

const adminAccountsStore = useAdminAccountsStore()
const router = useRouter()

const account = computed(() => {
  if (props.accountId !== undefined) {
    return _.cloneDeep(adminAccountsStore.getAccountById(props.accountId)!)
  } else {
    return new Account()
  }
})

const upsertAccount = async (account: Account) => {
  if (account.id === undefined) {
    const newAccount = await adminAccountsStore.createAccount(account.name)
    router.push({ name: "wc-admin-account", params: { accountId: newAccount.id } })
  } else {
    await adminAccountsStore.updateAccount(account)
  }
}
</script>

<style scoped lang="scss">
input[type="text"],
textarea,
select {
  @apply invalid:border-error;
}
</style>
