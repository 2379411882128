import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteLocation, RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"
import AssetView from "./AssetView.vue"
import RegisterAssetView from "./RegisterAssetView.vue"
import RegisterAssetConfirmationView from "./RegisterAssetConfirmationView.vue"
import AssetLayout from "@/modules/asset/AssetLayout.vue"
import AssetGroupView from "@/modules/asset/AssetGroupView.vue"
import UploadAssetTimeSeriesView from "./UploadAssetTimeSeriesView.vue"
import UploadAssetsView from "./UploadAssetsView.vue"
import ReviewAssetTimeSeriesUploadView from "./ReviewAssetTimeSeriesUploadView.vue"
import ReviewAssetUploadView from "./ReviewAssetUploadView.vue"

const assetIdProp = (route: RouteLocation) => ({ assetId: parseInt(scalarFromVectorOrScalar(route.params.assetId)) })
const assetGroupIdProp = (route: RouteLocation) => ({ assetGroupId: parseInt(scalarFromVectorOrScalar(route.params.assetGroupId)) })

export const assetRoutes: Array<RouteRecordRaw> = [
  {
    path: "/assets",
    name: "wc-assets",
    redirect: { name: "wc-assets" },
  },
  {
    path: "/attestation",
    name: "wc-attestation",
    component: RegisterAssetView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "WEATS Registration Attestation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/attestation/confirmation",
    name: "wc-attestation-confirmation",
    component: RegisterAssetConfirmationView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Attestation submitted" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/upload",
    name: "wc-assets-upload",
    component: UploadAssetsView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Upload asset metadata to WEATS" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/review-upload",
    name: "wc-assets-review-upload",
    component: ReviewAssetUploadView,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Review asset upload" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId",
    name: "wc-assets-asset",
    component: AssetView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Asset Details" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId/upload-timeseries",
    name: "wc-assets-asset-timeseries-upload",
    component: UploadAssetTimeSeriesView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Upload asset time series data" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/:assetId/review-timeseries-upload",
    name: "wc-assets-asset-review-timeseries-upload",
    component: ReviewAssetTimeSeriesUploadView,
    props: assetIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Upload asset time series data" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/asset-groups/:assetGroupId",
    name: "wc-asset-groups-asset-group",
    component: AssetGroupView,
    props: assetGroupIdProp,
    meta: { layout: AssetLayout, appPortal: AppPortal.Dashboard, title: "Asset Group Details" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/devices/register",
    name: "wc-register-device",
    redirect: { name: "wc-attestation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/devices/register/confirmation",
    name: "wc-register-device-confirmation",
    redirect: { name: "wc-attestation-confirmation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/register",
    name: "wc-register-asset",
    redirect: { name: "wc-attestation" },
    beforeEnter: authorizedRoute,
  },
  {
    path: "/assets/register/confirmation",
    name: "wc-register-asset-confirmation",
    redirect: { name: "wc-attestation-confirmation" },
    beforeEnter: authorizedRoute,
  },
]
