import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import Assets from "@/modules/asset/Assets.vue"
import Dashboard from "@/modules/dashboard/Dashboard.vue"
import AppLayout from "@/components/layout/AppLayout.vue"
import EacActivityLog from "@/modules/eacs/EacActivityLog.vue"

export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "wc-dashboard",
    component: Dashboard,
    meta: { layout: AppLayout, appPortal: AppPortal.Dashboard },
    redirect: { name: "wc-assets" },
    beforeEnter: authorizedRoute,
    children: [
      { name: "wc-assets", path: "assets", component: Assets },
      { name: "wc-eac-activity-log", path: "eac-activity-log", component: EacActivityLog },
    ],
  },
]
