<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AppPageSection class="text-body-2 pb-8">
    <AppPageSectionHeader>Assets</AppPageSectionHeader>
    <AppPageSectionDescription>
      View and manage assets that you have registered on WEATS. Each of these represents either an energy-consuming asset or an energy intervention
      resulting in EACs. Examples of energy interventions include solar panels, batteries, demand response events, and buildings that underwent an
      energy efficiency upgrade.
      <LearnMore :href="`${WWW_BASE_URL}/faq#suppliers`" />
    </AppPageSectionDescription>
  </AppPageSection>
  <AppPageSection class="text-body-2">
    <div class="flex items-baseline justify-between">
      <h3 class="text-subheading-1 mb-3">Asset Groups</h3>
      <WcCTA text="Create Asset Group" size="medium" weight="heavy" icon="wc-carbon:add" icon-position="right" @click="showCreateAssetGroupModal" />
    </div>
    <hr />
    <div class="mt-6 flex grow flex-wrap items-center justify-between gap-3">
      <div class="flex flex-wrap gap-3">
        <WcButton
          v-for="(label, key) in ASSET_GROUP_SORT_BY"
          :key="key"
          :variant="key === assetGroupSort.key ? 'primary' : 'secondary'"
          color="sage"
          :text="label"
          size="small"
          :icon="iconForSortButton(key)"
          icon-position="right"
          @click="handleToggleSortGroups(key)" />
      </div>
      <WcInputText
        v-model="filterAssetGroupName"
        icon="wc-carbon:search"
        icon-position="left"
        class="shrink-0 basis-56"
        name="filterAssetGroupName"
        inset-label="Search"
        size="small" />
    </div>
    <div class="mb-5 mt-8 overflow-x-scroll">
      <AssetGroupsSection
        v-if="!isLoading && !hasAssetGroupError"
        :asset-groups="assetGroups"
        @row-clicked="handleAssetGroupRowClicked"
        @row-delete-clicked="handleAssetGroupRowDeleteClicked"
        @asset-group-updated="handleAssetGroupUpdated" />
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasAssetGroupError" error-message="There was a problem loading asset groups. Please try again." />
    </div>
    <PaginationButtons class="mt-3" :page-info="assetGroupsPageInfo" :is-disabled="isLoading" @load-items="loadAssetGroups" />
  </AppPageSection>
  <AppPageSection
    v-if="
      isLoadingOrderSummaries ||
      hasOrderSummaryError ||
      orderSummaries.length > 0 ||
      isLoadingPurchaseSummaries ||
      hasPurchaseSummaryError ||
      purchaseSummaries.length > 0
    "
    class="text-body-2">
    <h3 class="text-subheading-1 mb-3">Procurements</h3>
    <hr />
    <ThirdPartyAssetsSection
      v-if="!(isLoadingOrderSummaries || isLoadingPurchaseSummaries) && !(hasOrderSummaryError || hasPurchaseSummaryError)"
      :order-summaries="orderSummaries"
      :purchase-summaries="purchaseSummaries" />
    <InlineLoading v-if="isLoadingOrderSummaries || isLoadingPurchaseSummaries" />
    <InlineError
      v-if="hasOrderSummaryError || hasPurchaseSummaryError"
      error-message="There was a problem loading third party assets. Please try again." />
  </AppPageSection>
  <AppPageSection class="mt-2">
    <h3 class="text-subheading-1 mb-3 mt-12">Ungrouped Assets</h3>
    <hr class="mb-6" />
    <div class="flex flex-wrap items-end justify-between gap-2">
      <div class="flex grow flex-wrap items-center gap-3">
        <WcDropdown
          v-model="filterAssetStatus"
          class="shrink-0 basis-40"
          name="filterAssetStatus"
          :options="statusOptions"
          inset-label="Status"
          size="small" />
        <WcCalendarRange
          v-model="filterAssetCreatedDateRange"
          class="shrink-0 basis-40"
          :class="{ 'basis-56': (filterAssetCreatedDateRange || [])[1] != null }"
          name="filterAssetCreatedDateRange"
          inset-label="Created date"
          size="small" />
        <WcInputText
          v-model="filterAssetLocationKeyword"
          icon="wc-carbon:search"
          class="shrink-0 basis-48"
          name="filterAssetLocationKeyword"
          inset-label="Filter by location..."
          size="small" />
        <WcCTA
          class="shrink-0 basis-52 pl-1"
          icon-position="right"
          icon="wc-carbon:close-outline"
          text="Clear Filters"
          size="small"
          @click="handleClearFilters" />
      </div>
      <WcViewModeButton v-model="viewMode" class="ml-auto" size="small" />
    </div>
    <div>
      <div v-if="viewMode === ViewMode.table">
        <div class="mt-4 max-h-[600px] overflow-x-scroll pb-3">
          <AssetsTable
            :assets="assets"
            allow-multiple-selection
            :selected-row-ids="selectedAssetIds"
            show-eacs
            show-kind
            @row-clicked="handleRowClicked"
            @rows-selected="handleRowsSelected" />
          <PaginationButtons class="mt-3" :page-info="assetsPageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
        </div>
        <div v-if="selectedAssetIds.length" class="mt-4 flex items-center justify-between">
          <div class="text-subheading-1">{{ selectedAssetIds.length }} selected</div>
          <div class="flex flex-wrap justify-end gap-3">
            <WcButton variant="secondary" text="Cancel" size="small" @click="handleClearSelection" />
            <WcButton v-if="assetGroups.length > 0" text="Add to Group" size="small" @click="handleAddAssetsToGroup" />
            <WcButton text="Create New Group" size="small" @click="handleCreateGroupWithAssets" />
          </div>
        </div>
      </div>
      <div v-if="viewMode === ViewMode.map && assetLocations?.length > 0">
        <div class="relative my-4 size-full h-[600px]">
          <WcMap :locations="assetLocations ?? []" class="mb-8" locations-are-precise>
            <template #popup="asset">
              <AssetPopup :asset="asset" />
            </template>
          </WcMap>
          <PaginationButtons :page-info="assetsPageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
        </div>
      </div>
    </div>
  </AppPageSection>
  <EmptyState v-if="!isLoading && !hasAssetError && assetTotalCount === 0" title="No registered assets">
    <WcRouterLink :route="{ name: 'wc-attestation' }" class="text-hyperlink" :is-back-link="false">Submit an attestation</WcRouterLink> to begin
    registering your assets on <a :href="`${WWW_BASE_URL}/weats`" target="_blank" class="text-hyperlink">WEATS</a>.
    <p class="mt-4">If you have recently submitted your attestation, a member of the WattCarbon team will email you with next steps.</p>
  </EmptyState>
  <InlineLoading v-if="isLoading" />
  <InlineError v-if="hasAssetError" error-message="There was a problem loading assets. Please try again." />
  <AddAssetGroupModal ref="addAssetGroupModal" @asset-group-added="handleAssetGroupAdded" />
  <AddAssetGroupWithAssetsModal ref="addAssetGroupWithAssetsModal" @asset-group-added="handleAssetGroupAdded" />
  <AddAssetsToGroupModal ref="addAssetsToGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
</template>

<script setup lang="ts">
import { format } from "date-fns"
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { WcButton, WcCTA } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { ViewMode, WcCalendarRange, WcDropdown, WcInputText, WcViewModeButton } from "@/components/input"
import { AppPageSection, AppPageSectionDescription, AppPageSectionHeader } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import LearnMore from "@/components/ui/LearnMore.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcRouterLink from "@/components/WcRouterLink.vue"
import WcMap from "@/components/WcMap/WcMap.vue"
import { AssetGroupOrderBy, AssetGroupSortBy, ASSET_GROUP_SORT_BY, mapAssetsToLocations } from "@/modules/asset/asset.service"
import type { AssetGroupWithSummary, AssetWithSummary } from "@/modules/asset/asset.service"
import type { AssetGroup, AssetsSummary, AssetStatus } from "@/models/asset"
import { ASSET_STATUS } from "@/models/asset"
import { OrderSummary } from "@/models/order"
import { PurchaseSummary } from "@/models/listing"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService, useOrdersService, useListingService } from "@/services/service-container"
import AssetGroupsSection from "./components/AssetGroupsSection.vue"
import AssetPopup from "./components/AssetPopup.vue"
import AssetsTable from "./components/AssetsTable.vue"
import { debounce } from "@/utils/debounce"
import { getEnvironment } from "@/environment"
import AddAssetGroupModal from "./components/AddAssetGroupModal.vue"
import AddAssetGroupWithAssetsModal from "./components/AddAssetGroupWithAssetsModal.vue"
import AddAssetsToGroupModal from "./components/AddAssetsToGroupModal.vue"
import ThirdPartyAssetsSection from "./components/ThirdPartyAssetsSection.vue"

const { WWW_BASE_URL } = getEnvironment()

const router = useRouter()
const toast = useToast()
const assetService = useAssetService()
const ordersService = useOrdersService()
const listingService = useListingService()

const assetGroups = ref<AssetGroupWithSummary[]>([])
const assets = ref<AssetWithSummary[]>([])
const hasAssetError = ref<boolean>(false)
const hasAssetGroupError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const assetsPageInfo = ref<PageInfo>()
const assetGroupsPageInfo = ref<PageInfo>()
const viewMode = ref<ViewMode>(ViewMode.table)
const assetGroupSort = ref<{ key: AssetGroupSortBy; direction: AssetGroupOrderBy }>({
  key: AssetGroupSortBy.group_name,
  direction: AssetGroupOrderBy.asc,
})

const orderSummaries = ref<OrderSummary[]>([])
const isLoadingOrderSummaries = ref<boolean>(true)
const hasOrderSummaryError = ref<boolean>(false)

const purchaseSummaries = ref<PurchaseSummary[]>([])
const isLoadingPurchaseSummaries = ref<boolean>(true)
const hasPurchaseSummaryError = ref<boolean>(false)

const filterAssetGroupName = ref<string | null | undefined>(null)

const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)
const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const assetCountsByStatus = ref<AssetsSummary["countsByStatus"]>()
const assetTotalCount = ref<number>(0)
const selectedAssetGroupIds = ref<number[]>([])
const selectedAssetIds = ref<number[]>([])
const addAssetGroupModal = ref()
const addAssetGroupWithAssetsModal = ref()
const addAssetsToGroupModal = ref()

const loadAssetGroups = async (url?: string) => {
  try {
    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetGroupName.value && { name: filterAssetGroupName.value }),
    }
    const result = await assetService.listAssetGroups({
      per_page: 12,
      url,
      orderBy: assetGroupSort.value.direction,
      sortBy: assetGroupSort.value.key,
      ...filters,
    })
    assetGroups.value = result.data
    assetGroupsPageInfo.value = result.pageInfo
    selectedAssetGroupIds.value = []
    hasAssetGroupError.value = false
  } catch (error) {
    hasAssetGroupError.value = true
    console.error("There was an error loading asset groups", error)
  }
  isLoading.value = false
}

const loadAssets = async (url?: string) => {
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]
    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
    }
    const result = await assetService.listAssets({ url, ungrouped: true, ...filters })
    assets.value = result.data
    assetsPageInfo.value = result.pageInfo
    selectedAssetIds.value = []
  } catch (error) {
    hasAssetError.value = true
    console.error("There was an error loading assets", error)
  }
  isLoading.value = false
}

const loadAssetsSummary = async () => {
  try {
    const result = await assetService.getAssetsSummary()
    assetCountsByStatus.value = result.countsByStatus
    assetTotalCount.value = result.assetCount
  } catch (error) {
    console.error("There was an error loading assets summary info", error)
  }
}

const loadOrderSummaries = async () => {
  try {
    orderSummaries.value = await ordersService.getOrdersSummary()
  } catch (error) {
    console.error("There was an error loading order summary info", error)
    hasOrderSummaryError.value = true
  } finally {
    isLoadingOrderSummaries.value = false
  }
}

const loadPurchaseSummaries = async () => {
  try {
    purchaseSummaries.value = await listingService.getPurchaseSummaries()
  } catch (error) {
    console.error("There was an error loading purchase summary info", error)
    hasPurchaseSummaryError.value = true
  } finally {
    isLoadingPurchaseSummaries.value = false
  }
}

onMounted(async () => {
  loadAssetsSummary()
  loadAssetGroups()
  loadOrderSummaries()
  loadPurchaseSummaries()
  loadAssets()
})

watch([filterAssetStatus], () => {
  loadAssets()
})

// Debounced asset filters
watch(
  [filterAssetCreatedDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAssets()
  })
)
// Debounced asset group filters
watch(
  [filterAssetGroupName],
  debounce(() => {
    loadAssetGroups()
  })
)
const assetLocations = computed(() => mapAssetsToLocations(assets.value))

const iconForSortButton = (key: AssetGroupSortBy) => {
  if (assetGroupSort.value.key === key) {
    return assetGroupSort.value.direction === AssetGroupOrderBy.asc ? "wc-carbon:arrow-up" : "wc-carbon:arrow-down"
  }
  return undefined
}

const handleToggleSortGroups = (key: AssetGroupSortBy) => {
  if (assetGroupSort.value.key === key) {
    assetGroupSort.value.direction = assetGroupSort.value.direction === AssetGroupOrderBy.asc ? AssetGroupOrderBy.desc : AssetGroupOrderBy.asc
  } else {
    assetGroupSort.value.key = key
    if (key === AssetGroupSortBy.created_time || key === AssetGroupSortBy.updated_time) {
      // For the date sort options, default to descending order (newest first)
      assetGroupSort.value.direction = AssetGroupOrderBy.desc
    } else {
      assetGroupSort.value.direction = AssetGroupOrderBy.asc
    }
  }
  loadAssetGroups()
}

const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleRowsSelected = (selectedRowIDs: number[]) => {
  selectedAssetIds.value = selectedRowIDs
}

const handleClearSelection = () => {
  selectedAssetIds.value = []
}

const handleAddAssetsToGroup = () => {
  const assetsToAdd = assets.value.filter((asset) => selectedAssetIds.value.includes(asset.id))
  addAssetsToGroupModal.value.openModal(assetsToAdd)
}

const handleCreateGroupWithAssets = () => {
  const assetsToAdd = assets.value.filter((asset) => selectedAssetIds.value.includes(asset.id))
  addAssetGroupWithAssetsModal.value.openModal(assetsToAdd)
}

const handleClearFilters = () => {
  filterAssetStatus.value = null
  filterAssetLocationKeyword.value = null
  filterAssetCreatedDateRange.value = null
  loadAssets()
}

const showCreateAssetGroupModal = () => {
  addAssetGroupModal.value.openModal()
}

const handleAssetGroupRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/asset-groups/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleAssetGroupRowDeleteClicked = async (assetGroupId: number, event: MouseEvent) => {
  event.stopPropagation()
  const assetGroup = assetGroups.value.find((assetGroup) => assetGroup.id === assetGroupId)
  if (assetGroup && confirm(`Are you sure you want to delete asset group '${assetGroup.name}'?`)) {
    try {
      await assetService.deleteAssetGroup(assetGroup.id)
      loadAssetGroups()
      toast.success(`Asset group ${assetGroup.name} deleted`)
    } catch (error) {
      console.error("There was an error deleting the asset group", error)
      toast.error("There was an error deleting the asset group. Please try again.")
    }
  }
}

const handleAssetGroupUpdated = () => {
  loadAssetGroups()
  loadAssets()
}

const handleAssetGroupAdded = (addedGroup: AssetGroup) => {
  router.push({ name: "wc-asset-groups-asset-group", params: { assetGroupId: addedGroup.id } })
}
</script>
