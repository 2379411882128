import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import CarbonAccountingDashboardView from "@/modules/carbonAccountingDashboard/CarbonAccountingDashboardView.vue"
import AppLayout from "@/components/layout/AppLayout.vue"
export const carbonAccountingDashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: "/carbon-accounting-dashboard",
    name: "wc-carbon-accounting-dashboard",
    component: CarbonAccountingDashboardView,
    meta: { layout: AppLayout, appPortal: AppPortal.CarbonAccounting },
    beforeEnter: authorizedRoute,
  },
]
