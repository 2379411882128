import { Account, User } from "@/models/models"
import { getEnvironment } from "@/environment"

export type PartialLoginStateAccount = Pick<Account, "id" | "logo_url" | "name" | "type">

export interface LoginSharedState {
  account?: PartialLoginStateAccount
  isAdmin: boolean
  isAuthenticated: boolean
}

interface EnvironmentStates {
  [key: string]: LoginSharedState
}

export default class LoginSharedStateService {
  static readonly COOKIE_NAME = "wattcarbon_login_state"
  static readonly DEFAULT_STATE: LoginSharedState = {
    isAdmin: false,
    isAuthenticated: false,
  }

  private static getLoginStates(): EnvironmentStates {
    const match = document.cookie.match(`(^|;)\\s*${this.COOKIE_NAME}\\s*=\\s*([^;]+)`)
    if (!match) return {}

    try {
      return JSON.parse(match[2]) as EnvironmentStates
    } catch (e) {
      console.error("Failed to parse login shared state cookie:", e)
      return {}
    }
  }

  private static setLoginStates(states: EnvironmentStates): void {
    const { BASE_DOMAIN } = getEnvironment()
    document.cookie = `${this.COOKIE_NAME}=${JSON.stringify(states)}; domain=.${BASE_DOMAIN}; path=/`
  }

  static set(user: User, accounts?: Account[]): void {
    const environment = getEnvironment().DEPLOYMENT
    const states = this.getLoginStates()

    const fullAccount = (accounts ?? []).find((a) => a.id === user.account_id)
    const account = fullAccount
      ? {
          id: fullAccount.id,
          logo_url: fullAccount.logo_url,
          name: fullAccount.name,
          type: fullAccount.type,
        }
      : undefined

    states[environment] = {
      account,
      isAdmin: user.roles && user.roles.includes("super"),
      isAuthenticated: true,
    }

    this.setLoginStates(states)
  }

  static get(): LoginSharedState {
    const environment = getEnvironment().DEPLOYMENT
    const states = this.getLoginStates()
    return states[environment] ?? this.DEFAULT_STATE
  }

  static clear(): void {
    const environment = getEnvironment().DEPLOYMENT
    const states = this.getLoginStates()
    states[environment] = this.DEFAULT_STATE
    this.setLoginStates(states)
  }
}
