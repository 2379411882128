import LoadView from "./VppLoadView.vue"
import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import AppLayout from "@/components/layout/AppLayout.vue"
export const vppLoadRoutes: Array<RouteRecordRaw> = [
  {
    path: "/load",
    name: "wc-load",
    component: LoadView,
    meta: { layout: AppLayout, appPortal: AppPortal.Dashboard, title: "Load" },
    beforeEnter: authorizedRoute,
  },
]
