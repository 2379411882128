<template>
  <router-link class="tab" :class="{ 'active-tab': isActive }" :to="to">
    <slot />
  </router-link>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useRoute } from "vue-router"
import type { RouteLocationRaw } from "vue-router"

const route = useRoute()

const props = defineProps<{ to: RouteLocationRaw }>()

const isActive = computed(() => {
  if (typeof props.to === "string") return route.path === props.to
  if ("name" in props.to) return route.name === props.to.name
  return false
})
</script>

<style lang="scss" scoped>
@import "./_tab.scss";
</style>
