<template>
  <div v-if="!!story" class="min-h-[600px] rounded-md bg-white p-3 shadow-md">
    <div class="flex w-full flex-row justify-between">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-xl break-words">{{ story.name }}</h1>

        <table>
          <tr>
            <th scope="row">Internal ID</th>
            <td>{{ story.id }}</td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td>{{ story.status }}</td>
          </tr>
        </table>
      </div>

      <div class="flex w-1/3 justify-end p-2">
        <img :src="story.imageUrl || undefined" class="max-h-[140px] max-w-[300px]" />
      </div>
    </div>

    <div class="mt-2 flex gap-2">
      <WcButton text="View" icon="wc-carbon:view" size="small" :disabled="!!story.id" :href="`${WWW_BASE_URL}/stories/${story.id}`" open-in-new-tab />

      <input
        ref="logoFileInputRef"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        class="hidden"
        :disabled="adminStoriesStore.isSaving"
        @input="uploadImage" />
      <WcButton
        text="Upload new image"
        icon="wc-carbon:upload"
        size="small"
        :is-disabled="adminStoriesStore.isSaving"
        @click="logoFileInputRef?.click()" />

      <WcButton
        text="Delete Story"
        color="alert"
        icon="wc-carbon:delete"
        size="small"
        :is-disabled="adminStoriesStore.isSaving"
        @click="deleteStory" />
    </div>

    <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
      <router-link class="tab" :to="{ name: 'wc-admin-story-config' }">Configuration</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-story-assets' }">Assets</router-link>
    </nav>

    <router-view></router-view>
  </div>
  <div v-else>
    <p>Story not found.</p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { WcButton } from "@/components/button"
import { useAdminStoriesStore } from "@/modules/admin/adminStories.state"
import { useRouter } from "vue-router"
import { getEnvironment } from "@/environment"

const { WWW_BASE_URL } = getEnvironment()

const adminStoriesStore = useAdminStoriesStore()
const router = useRouter()
const logoFileInputRef = ref<HTMLInputElement | null>(null)

const props = defineProps<{
  storyId: number
}>()

watchEffect(async () => {
  // Force a fetch from the backend when viewing an individual story,
  // to ensure we have the latest data in case someone else is editing
  // simultaneously.
  if (!isNaN(props.storyId)) {
    try {
      await adminStoriesStore.fetchStory(props.storyId)
    } catch {
      // ignore
    }
  }
})

const story = computed(() => adminStoriesStore.getStoryById(props.storyId)!)

const uploadImage = async (event: Event) => {
  const fileInput = event.target! as HTMLInputElement
  const file = fileInput.files?.[0]
  if (!file) {
    return
  }

  await adminStoriesStore.uploadImage(story.value, file)
}

const deleteStory = async () => {
  if (confirm(`Delete story '${story.value.name}'?`)) {
    await adminStoriesStore.deleteStory(story.value)
    router.push({ name: "wc-admin-stories" })
  }
}
</script>

<style scoped lang="scss">
table th {
  @apply text-left font-normal pr-8;
}

.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
