import { LoadshapeRow, Loadshape } from "@/models/loadshape"
import { format, addMonths, startOfYear } from "date-fns"
import { NetZeroOverTimeData } from "@/components/visualizations/visualizationModels"
import { VizDateFormat } from "@/components/visualizations/VizHelper"

export class GraphHelper {
  static getYearMonths(startDate: Date, endDate: Date) {
    const yearMonths = []
    while (startDate <= endDate) {
      yearMonths.push(format(startDate, "yyyy-MM"))
      startDate = addMonths(startDate, 1)
    }
    return yearMonths
  }

  static getYearToDateMonths() {
    return this.getYearMonths(startOfYear(new Date()), addMonths(new Date(), -1))
  }

  static getForecastYearMonths() {
    return this.getYearMonths(new Date(), addMonths(new Date(), 12))
  }

  static getSummaryYearMonths() {
    const startDate = addMonths(startOfYear(new Date()), -1)
    return this.getYearMonths(startDate, addMonths(startDate, 24))
  }

  static getGraphValues(hours: Array<LoadshapeRow>, isEnergy = true) {
    const fieldValue = isEnergy ? "marketBasedEnergySumMwh" : "marketBasedCarbonSumKgCo2"
    return hours.map((h: LoadshapeRow) => {
      return {
        value: h[fieldValue],
        label: format(h.datetime, VizDateFormat.monthYear),
        date: h.datetime,
      }
    })
  }

  static getGraphData(assetLoadshape: Loadshape, orderLoadshape: Loadshape, isEnergy = true) {
    const data = new NetZeroOverTimeData()
    data.name = isEnergy ? "MWh" : "tCO₂"
    if (Loadshape.getSourceCount(assetLoadshape) > 0) {
      data.consumed = GraphHelper.getGraphValues(assetLoadshape.rows, isEnergy)
    }
    if (Loadshape.getSourceCount(orderLoadshape) > 0) {
      data.produced = GraphHelper.getGraphValues(orderLoadshape.rows, isEnergy)
    }

    return data
  }
}
