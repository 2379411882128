<template>
  <form class="flex flex-col gap-2" @submit.prevent="upsertStory(story)">
    <label>
      Name *
      <input v-model="story.name" type="text" required />
    </label>

    <label>
      Subtitle
      <input v-model="story.subtitle" type="text" />
    </label>

    <label>
      Status *
      <select v-model="story.status" class="p-2" required>
        <option v-for="key in StoryStatusType" :key="key" :value="key">{{ key }}</option>
      </select>
    </label>

    <label>
      Short Description *
      <textarea v-model="story.shortDescription" class="p-2" rows="3" required></textarea>
    </label>

    <label>
      About this Partner (Markdown)
      <textarea v-model="story.longDescription" class="p-2" rows="10"></textarea>
    </label>

    <label>
      Grid Region Description
      <input v-model="story.locationDescription" type="text" />
    </label>

    <label>
      Dates Description *
      <input v-model="story.dateRangeDescription" type="text" placeholder="January" required />
    </label>

    <label>
      Type Description
      <input v-model="story.availabilityDescription" type="text" />
    </label>

    <label>
      Resource Description
      <input v-model="story.deviceKindDescription" type="text" placeholder="" />
    </label>

    <label>
      Volume Description
      <input v-model="story.volumeDescription" type="text" placeholder="" />
    </label>

    <label>
      Price Description
      <input v-model="story.priceDescription" type="text" placeholder="" />
    </label>

    <label>
      Methodology Description (Markdown)
      <textarea v-model="story.methodologyDescription" class="p-2" rows="2"></textarea>
    </label>

    <label>
      Measurement and Verification Plan Title
      <input v-model="story.measurementAndVerificationPlanTitle" type="text" placeholder="" />
    </label>

    <label>
      Measurement and Verification Plan URL
      <input v-model="story.measurementAndVerificationPlanUrl" type="text" placeholder="" />
    </label>

    <label>
      Co-Benefits (Markdown)
      <textarea v-model="story.bodyContent" class="p-2" rows="6"></textarea>
    </label>

    <label>
      Impacts (Markdown, one per line)
      <textarea :value="impacts" class="p-2" rows="6" @change="handleChangeImpacts"></textarea>
    </label>

    <label>
      Impact Statements (Markdown, one per line)
      <textarea :value="impactStatements" class="p-2" rows="6" @change="handleChangeImpactStatements"></textarea>
    </label>

    <label>
      Impact Quote (Markdown)
      <textarea v-model="story.impactQuote" class="p-2" rows="2"></textarea>
    </label>

    <label>
      Impact Quote Attribution
      <input v-model="story.impactQuoteAttribution" type="text" class="p-2" />
    </label>

    <WcButton text="Save" type="submit" icon="wc-carbon:save" class="w-fit" />
  </form>
</template>

<script setup lang="ts">
import { useAdminStoriesStore } from "@/modules/admin/adminStories.state"
import { computed } from "vue"
import _ from "lodash"
import { AdminStory, StoryStatusType } from "@/models/story"
import { WcButton } from "@/components/button"
import { useRouter } from "vue-router"

const props = defineProps<{
  storyId?: number
}>()

const adminStoriesStore = useAdminStoriesStore()
const router = useRouter()

const story = computed(() => {
  if (props.storyId !== undefined) {
    return _.cloneDeep(adminStoriesStore.getStoryById(props.storyId)!)
  } else {
    return new AdminStory()
  }
})

const impacts = computed(() => {
  if (!story.value) {
    return ""
  }
  return (story.value?.impacts ?? []).join("\n")
})

const handleChangeImpacts = (event: Event) => {
  const value = (event.target as HTMLTextAreaElement).value?.trim() ?? ""
  story.value.impacts = value === "" ? [] : value.split("\n")
}

const impactStatements = computed(() => {
  if (!story.value) {
    return ""
  }
  return (story.value?.impactStatements ?? []).join("\n")
})

const handleChangeImpactStatements = (event: Event) => {
  const value = (event.target as HTMLTextAreaElement).value?.trim() ?? ""
  story.value.impactStatements = value === "" ? [] : value.split("\n")
}

const upsertStory = async (story: AdminStory) => {
  if (story.id === undefined) {
    const newStory = await adminStoriesStore.createStory(story)
    router.push({ name: "wc-admin-story", params: { storyId: newStory.id } })
  } else {
    await adminStoriesStore.updateStory(story)
  }
}
</script>

<style scoped lang="scss">
input[type="text"],
input[type="date"],
textarea,
select {
  @apply invalid:border-error;
}
</style>
