<template>
  <AppPage class="bg-background-sagelight">
    <div v-if="$slots.subheader">
      <div class="wc-page-container w-full">
        <slot name="subheader" />
      </div>
    </div>
    <AppPageContent>
      <div class="mt-4 flex gap-4">
        <WcSideNav :selected-asset-ids="props.selectedAssetIds" :apply-button-is-disabled="props.applyButtonIsDisabled" @update="onSideNavUpdate" />
        <slot />
      </div>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { PropType } from "vue"
import { Site } from "@/models/site"
import { SideNavFilters } from "@/components/layout/layout.model"
import WcSideNav from "@/components/layout/WcSideNav.vue"
import { AppPage, AppPageContent } from "@/components/layout"

const props = defineProps({
  selectedSiteIds: { type: Array as PropType<Array<number>>, default: new Array<Site>(), required: false },
  selectedAssetIds: { type: Array as PropType<Array<number>>, default: new Array<Site>(), required: true },
  applyButtonIsDisabled: { type: Boolean, default: false, required: false },
})

const emit = defineEmits(["update"])

const onSideNavUpdate = (assetIds: Array<number>, filters: SideNavFilters) => {
  emit("update", assetIds, filters)
}
</script>

<style scoped></style>
