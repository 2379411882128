<template>
  <section class="page disable-select">
    <h2>Signing out...</h2>
  </section>
</template>

<script lang="ts" setup>
import { onMounted } from "vue"
import posthog from "posthog-js"
import { getEnvironment } from "@/environment"
import { useMainStore } from "@/store"
import { useAuthService } from "@/services/service-container"

const { WWW_BASE_URL } = getEnvironment()

const service = useAuthService()
const store = useMainStore()

onMounted(() => {
  posthog.capture("Clicked on the 'Sign Out' link - Nav Header")
  if (store.auth.isAuthenticated) {
    service.logout()
  }
  window.location.href = WWW_BASE_URL
})
</script>
