<template>
  <AppPageSection class="w-full bg-highlight-pastelazure p-4">
    <WcDataWithLabel>
      <template #label>
        <span>Status</span>
      </template>
      <template #content>
        <div class="mb-2 flex items-center">
          <AssetStatusIcon :status="props.asset.status" />
          <div>
            <strong>{{ ASSET_STATUS[props.asset.status]?.summary }}</strong>
            <p v-if="asset.status === AssetStatus.changes_requested">{{ asset.reviewNotes }}</p>
          </div>
        </div>
      </template>
    </WcDataWithLabel>
  </AppPageSection>
</template>

<script setup lang="ts">
import { AppPageSection } from "@/components/layout"
import WcDataWithLabel from "@/components/ui/WcDataWithLabel.vue"
import type { Asset } from "@/models/asset"
import { ASSET_STATUS, AssetStatus } from "@/models/asset"
import AssetStatusIcon from "./AssetStatusIcon.vue"
const props = defineProps<{
  asset: Asset
}>()
</script>
