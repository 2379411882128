import { defineStore } from "pinia"
import { AdminStory } from "@/models/story"
import { ref, computed } from "vue"
import { TYPE, useToast } from "vue-toastification"
import { useStoryService } from "@/services/service-container"

export const useAdminStoriesStore = defineStore("adminStories", () => {
  const isLoading = ref<boolean>(true)
  const isSaving = ref<boolean>(false)
  const storiesMap = ref<Map<number, AdminStory>>(new Map())

  const storyService = useStoryService()
  const toast = useToast()

  const loadStories = async () => {
    isLoading.value = true
    try {
      const storiesArray = await storyService.listStoriesAdmin()
      storiesMap.value = new Map(storiesArray.map((p) => [p.id, p]))
    } finally {
      isLoading.value = false
    }
  }

  const _toastify = <T extends Array<any>, U>(message: string, fn: (...args: T) => U) => {
    return async (...args: T): Promise<U> => {
      isSaving.value = true
      const toastId = toast(`${message}…`, { id: "Stories", type: TYPE.DEFAULT, timeout: false })

      try {
        const result = await fn(...args)
        toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
        return result
      } catch (err) {
        toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
        throw err
      } finally {
        isSaving.value = false
      }
    }
  }

  const fetchStory = async (storyId: number) => {
    storiesMap.value.set(storyId, await storyService.getStory(storyId))
  }

  const createStory = _toastify("Saving", async (story: AdminStory) => {
    const newStory = await storyService.addStory(story)
    storiesMap.value.set(newStory.id, newStory)
    return newStory
  })

  const updateStory = _toastify("Saving", async (story: AdminStory) => {
    const updatedStory = await storyService.updateStory(story)
    storiesMap.value.set(updatedStory.id, updatedStory)
    return updatedStory
  })

  const uploadImage = _toastify("Uploading", async (story: AdminStory, file: File) => {
    const updatedStory = await storyService.uploadImage(story.id, file)
    storiesMap.value.set(updatedStory.id, updatedStory)
    return updatedStory
  })

  const deleteStory = _toastify("Deleting", async (story: AdminStory) => {
    await storyService.deleteStory(story.id)
    storiesMap.value.delete(story.id)
  })

  const stories = computed(() => Array.from(storiesMap.value.values()))

  const getStoryById = computed(() => {
    return (id: number) => storiesMap.value.get(id)
  })

  // load stories on first instantiation
  loadStories()

  return {
    // state
    isLoading,
    isSaving,
    // actions
    loadStories,
    fetchStory,
    createStory,
    updateStory,
    uploadImage,
    deleteStory,
    // getters
    stories,
    getStoryById,
  }
})
