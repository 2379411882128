<template>
  <h5 class="text-subheading-2 mb-4"><span v-if="vpp.length > 0">VPP</span> Fuel Mix</h5>
  <div class="text-body-3">
    <div class="flex flex-col gap-3">
      <div v-if="vpp.length > 0" class="mb-2">
        <div v-for="fuel in vpp" :key="fuel.name" class="flex w-full items-center gap-1 text-xs">
          <span class="basis-1/4 capitalize">{{ fuel.name }}</span>
          <div class="grow">
            <div :style="{ width: `${fuel.value}%` }" class="h-3 bg-blue-40" style="min-width: 1px"></div>
          </div>
          <span class="basis-8">{{ fuel.value }}%</span>
        </div>
      </div>
    </div>
    <div v-if="vpp.length > 0">
      <h5 class="text-subheading-2 my-4">Residual Fuel mix</h5>
    </div>
    <div class="flex flex-col gap-3">
      <div v-for="fuel in carbonFreeNonZero" :key="fuel.name" class="flex w-full items-center gap-1 text-xs">
        <span class="basis-1/4 capitalize">{{ fuel.name }}</span>
        <div class="grow">
          <div :style="{ width: `${fuel.value}%`, background: store.colors.carbonFree }" class="h-3" style="min-width: 1px"></div>
        </div>
        <span class="basis-8">{{ fuel.value }}%</span>
      </div>
      <div v-for="fuel in carbonBasedNonZero" :key="fuel.name" class="flex w-full items-center gap-1 text-xs">
        <span class="basis-1/4 capitalize">{{ fuel.name }}</span>
        <div class="grow">
          <div :style="{ width: `${fuel.value}%`, background: store.colors.carbonBased }" class="h-3" style="min-width: 1px"></div>
        </div>
        <span class="basis-8">{{ fuel.value }}%</span>
      </div>
    </div>
  </div>
  <div class="text-subheading-3 mt-2 border-t pt-2">
    <div class="flex items-center gap-2">
      <div class="flex grow items-center gap-2">
        <div class="size-2" :style="{ backgroundColor: store.colors.carbonFree }" />
        Carbon-free
      </div>
      <div class="basis-8">{{ carbonFreeTotalPercent }}%</div>
    </div>
    <div class="flex items-center gap-2">
      <div class="flex grow items-center gap-2">
        <div class="size-2" :style="{ backgroundColor: store.colors.carbonBased }" />
        Carbon-based
      </div>
      <div class="basis-8">{{ carbonBasedTotalPercent }}%</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libraries
import { PropType, computed } from "vue"

// Models
import { FuelMix, MarketFuelMix } from "@/models/loadshape"
import { useMainStore } from "@/store"

// Services

const props = defineProps({
  locational: { type: Object as PropType<FuelMix>, required: true },
  market: { type: Object as PropType<MarketFuelMix>, required: false },
})

const store = useMainStore()

const carbonBasedNonZero = computed(() => {
  return Object.entries(props.locational.carbonBased)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => {
      return {
        name: key.replace(/([A-Z])/, " $1"),
        value: Math.round((value / allFuelsTotalValue.value) * 100) || 0,
      }
    })
})

const carbonFreeNonZero = computed(() => {
  return Object.entries(props.locational.carbonFree)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => {
      return {
        name: key.replace(/([A-Z])/, " $1"),
        value: Math.round((value / allFuelsTotalValue.value) * 100) || 0,
      }
    })
})

const vpp = computed(() => {
  if (!props.market) {
    return []
  }

  return Object.entries(props.market)
    .filter(([, value]) => value > 0)
    .map(([key, value]) => {
      return {
        name: key.replace(/([A-Z])/, " $1"),
        value: Math.round((value / allFuelsTotalValue.value) * 100) || 0,
        label: {
          color: store.colors.carbonBased,
        },
      }
    })
})

/*
For carbon-free vs. carbon-based summary,
compute summed percentages from raw values instead of the percentages in the data
to avoid rounding issues and ensure that total percentages add up to 100%
*/

const allFuelsTotalValue = computed(() => {
  let values = Object.values(props.locational.carbonBased)
  values = values.concat(Object.values(props.locational.carbonFree))
  if (props.market) {
    values = values.concat(Object.values(props.market))
  }

  return values.reduce((total, fuel: number) => total + fuel, 0)
})

const carbonBasedTotalPercent = computed(() => {
  const carbonBasedTotalValue = Object.values(props.locational.carbonBased).reduce((total, fuel: number) => total + fuel, 0)

  return Math.round((carbonBasedTotalValue / allFuelsTotalValue.value) * 100) || 0 // 0 for NaN
})

const carbonFreeTotalPercent = computed(() => {
  let values = Object.values(props.locational.carbonFree)
  if (props.market) {
    values = values.concat(Object.values(props.market))
  }
  const carbonFreeTotalValue = values.reduce((total, fuel: number) => total + fuel, 0)
  return Math.round((carbonFreeTotalValue / allFuelsTotalValue.value) * 100) || 0 // 0 for NaN
})
</script>
