<template>
  <WcModal :is-open="isOpen" header="Edit Asset Group" @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <div v-if="assetGroup" class="sm:w-[424px]">
        <fieldset class="mb-8">
          <label class="text-body-2 mb-2 text-black" for="assetGroupName">Asset Group Name</label>
          <WcInputText id="assetGroupName" v-model="assetGroupName" name="assetGroupName" />
        </fieldset>
        <div class="mb-8 grid grid-cols-3 gap-4">
          <div class="col-span-1">
            <div class="text-subheading-large-bold">{{ assetGroup.deviceIds.length }}</div>
            <div class="text-body-3">Total assets</div>
          </div>
          <div class="col-span-2">
            <div class="text-subheading-large-bold">{{ netEacs.quantity + netEacs.unit }}</div>
            <div class="text-body-3">Total EACs created</div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full items-center justify-end gap-3">
        <WcButton text="Cancel" variant="secondary" size="small" @click="handleUpdateIsOpen(false)" />
        <WcButton text="Save" :is-disabled="!assetGroup || !assetGroupName" size="small" @click="updateAssetGroup" />
      </div>
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useToast } from "vue-toastification"
import type { AssetGroup } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import { WcButton } from "@/components/button"
import { WcInputText } from "@/components/input"
import WcModal from "@/components/WcModal.vue"
import type { AssetGroupSummary } from "@/modules/asset/asset.service"
import { netEacsFromValues } from "@/modules/registry/registry.utils"
import { useAssetService } from "@/services/service-container"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits<{
  "asset-group-updated": [assetGroup: AssetGroup | null]
}>()

const isOpen = ref<boolean>(false)
const assetGroup = ref<AssetGroup>()
const assetGroupSummary = ref<AssetGroupSummary>()
const assetGroupName = ref<string>("")

const eacSummaryEntries = computed(() => {
  if (!assetGroupSummary.value?.eacs.sum) return []
  return assetGroupSummary.value.eacs.sum
})

const netEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "0", unit: "" }
  const netEacs = netEacsFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledShort)
})

const handleUpdateIsOpen = async (newValue: boolean) => {
  isOpen.value = newValue
}

const updateAssetGroup = async () => {
  if (!assetGroup.value) {
    return
  }
  try {
    const updatedAssetGroup = await assetService.updateAssetGroup(assetGroup.value.id, { name: assetGroupName.value })
    emit("asset-group-updated", updatedAssetGroup)
    toast.success(`Asset group ${updatedAssetGroup.name} updated`)
    closeModal()
  } catch (error) {
    toast.error("There was a problem updating the asset group. Please try again.")
    throw error
  }
}

const openModal = async (assetGroupId: number) => {
  assetGroup.value = await assetService.getAssetGroup(assetGroupId)
  assetGroupSummary.value = await assetService.getAssetGroupSummary(assetGroupId)
  assetGroupName.value = assetGroup.value.name

  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>
