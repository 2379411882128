<template>
  <a v-if="type === 'anchor'" ref="button" :href="href" :class="outerClasses" :target="openInNewTab ? '_blank' : ''">
    <span :class="innerClasses">
      <slot />
    </span>
  </a>
  <button v-else-if="type === 'button' || type === 'submit'" ref="button" :class="outerClasses" :type="type" :disabled="isDisabled">
    <span :class="innerClasses">
      <slot />
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue"

const props = defineProps({
  type: { type: String, default: "button" },
  width: { type: String },
  path: { type: String },
  href: { type: String },
  openInNewTab: { type: String },
  buttonClasses: { type: String },
  isDisabled: { type: Boolean },
  theme: { type: String },
})

const hoverClasses = computed(() => {
  if (props.theme === "light") {
    return "hover:border-highlight"
  } else {
    return "hover:border-highlight-yellow"
  }
})

const outerClasses = computed(() => {
  return `inline-flex justify-center items-center flex-col shrink-0 relative px-0 ${props.width === "fixed" ? " w-200 md:w-[33.5rem]" : ""}${
    props.width === "fixed-large-mobile" ? " w-[28rem] md:w-[33.5rem]" : ""
  }${props.width === "full" ? " w-full" : ""}${props.isDisabled ? " opacity-25" : ""}`
})
const innerClasses = computed(() => {
  return `text-body-2 tracking-wider flex-1 flex justify-center items-center w-full -mb-1 border-b-2 border-[transparent] cursor-pointer ${props.buttonClasses} ${hoverClasses.value}`
})
</script>
