<template>
  <div v-if="!files">
    <p class="mt-2">Loading&hellip;</p>
  </div>
  <div v-if="files && files.length !== 0">
    <p class="mt-2">The following uploaded files are associated with this asset:</p>
    <ol class="mt-2 list-disc pl-4">
      <li v-for="file in files" :key="file.uploadedAt">Uploaded on {{ format(new Date(file.uploadedAt), "MMM d, yyyy 'at' HH:MM (z)") }}</li>
    </ol>
    <button class="btn btn-flat mt-4 p-0" @click="clearUploads()">Replace with new data</button>
  </div>
  <div v-if="files && files.length === 0">
    <div class="mt-2">
      <label>
        <span class="pl-0">CSV File<span class="pl-0 text-error" title="Required">*</span></span>
        <input ref="fileInput" type="file" accept="text/csv" @input="onFileInputChanged()" />
      </label>
    </div>
    <div class="mt-2">
      <label>
        Time zone
        <select v-model="timezone">
          <optgroup label="Automatic">
            <option value="local">Infer time zone from address</option>
            <option value="">Use time zones specified in CSV</option>
          </optgroup>
          <optgroup label="Manual">
            <option v-for="o in timezoneOptions" :key="o">{{ o }}</option>
          </optgroup>
        </select>
      </label>
    </div>
    <div class="mt-2 flex">
      <button class="btn" :disabled="isUploading || !isFileInputReady || !asset.name" @click="upload()">
        <Icon icon="wc-carbon:upload" />
        Upload File
      </button>
      <button class="btn btn-flat text-sm" @click="showInstructions(true)">Show Instructions</button>
    </div>
    <WcModal :is-open="isUploadInstructionsOpen" header="Upload Instructions" @update:is-open="showInstructions">
      <template #panel>
        <p>You can upload a file of energy measurements. Uploaded data should be formatted as comma-separated values (CSV). Here's a tiny example:</p>
        <pre class="bg-gray-200 mt-4 p-4">
datetime,energy_kwh
2021-01-01T00:00:00,0.1
2021-01-01T01:00:00,1.1
2021-01-01T02:00:00,2.3
2021-01-01T03:00:00,1.4
2021-01-01T04:00:00,1.6</pre
        >
        <p class="mt-4">
          The first row should be a header, defining the column names. Each subsequent row should represent an energy measurement. The following
          columns are required:
        </p>
        <ul class="mt-4 list-disc pl-4">
          <li>
            <code>datetime</code>&mdash;an <a href="https://en.wikipedia.org/wiki/ISO_8601" class="underline">ISO-8601 format</a> timestamp for the
            energy measurement. If a time zone offset (e.g. <code>-0800</code>) is not specified in the timestamp, specify a time zone when uploading.
          </li>
          <li>
            One of
            <ul class="list-disc pl-4">
              <li>
                <code>energy_kwh</code>&mdash;The total amount of energy produced or consumed during the measured interval, in kWh. The
                <code>datetime</code> is interpreted to be the end of the interval.
              </li>
              <li>
                <code>avg_power_kw</code>&mdash;The average energy produced or consumed during the measured interval, in kW. The
                <code>datetime</code> is interpreted to be the start of the interval.
              </li>
            </ul>
          </li>
        </ul>
        <p class="mt-4">
          Extra columns are ignored. If both <code>energy_kwh</code> and <code>avg_power_kw</code> are supplied, <code>energy_kwh</code> will be used.
        </p>
      </template>
      <template #footer>
        <WcButton text="Close" size="small" @click="showInstructions(false)" />
      </template>
    </WcModal>

    <WcModal :is-open="isErrorDialogOpen" header="Errors" @update:is-open="closeErrorDialog">
      <template #panel>
        <p>The following errors were found. Please make the necessary changes to your data and try again.</p>

        <table class="mt-4">
          <thead>
            <tr>
              <th scope="col" class="pr-4">Location</th>
              <th scope="col" class="text-left">Message</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="error in errorList.slice(0, MAX_ERRORS)" :key="error">
              <td class="align-top capitalize">{{ error.loc[0] }}</td>
              <td>{{ error.message }}</td>
            </tr>
          </tbody>
        </table>

        <p v-if="errorList.length > MAX_ERRORS" class="mt-4">&hellip;and {{ errorList.length - MAX_ERRORS }} more errors.</p>
      </template>
      <template #footer>
        <WcButton text="Close" size="small" @click="closeErrorDialog()" />
      </template>
    </WcModal>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed, PropType } from "vue"
import WcModal from "@/components/WcModal.vue"
import ApiFetcher from "@/services/api-fetcher"
import { SiteAsset } from "@/models/siteAsset"
import { Icon } from "@iconify/vue"
import { TYPE, useToast } from "vue-toastification"
import { format } from "date-fns"
import { useSiteService } from "@/services/service-container"
import { WcButton } from "@/components/button"
const MAX_ERRORS = 100

const props = defineProps({
  asset: { type: Object as PropType<SiteAsset>, required: true },
})
const emit = defineEmits(["uploaded", "update:fileCount"])

const siteService = useSiteService()
const toast = useToast()
const fetcher = new ApiFetcher()
const timezoneOptions = Intl.supportedValuesOf("timeZone").sort((a, b) => (a < b ? -1 : 1))

const isUploadInstructionsOpen = ref(false)
const showInstructions = (doShow = true) => {
  isUploadInstructionsOpen.value = doShow
}

const errorList = ref<Array<any>>([])
const closeErrorDialog = () => {
  errorList.value = []
}
const isErrorDialogOpen = computed(() => {
  return errorList.value.length != 0
})

const fileInput = ref<HTMLInputElement | null>(null)
const isFileInputReady = ref<boolean>(false)
const onFileInputChanged = () => {
  isFileInputReady.value = fileInput.value?.files?.length != 0
}

const asset = ref(props.asset)
const timezone = ref<string>("local")
const isUploading = ref<boolean>(false)
const upload = async () => {
  if (!asset.value.name) {
    return
  }
  if (asset.value.id == 0) {
    asset.value = await siteService.saveAsset(asset.value)
  }

  const fileData = fileInput.value?.files?.[0]
  if (!fileData) {
    return
  }

  const toastId = toast("Uploading…", { id: "WcAssetDataUpload", type: TYPE.DEFAULT, timeout: false })
  try {
    isUploading.value = true
    await fetcher.httpUpload(`/assets/${asset.value.id}/files/energy`, { timezone: timezone.value }, fileData)
    toast.update(toastId, { content: "Uploaded!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail
    if (Array.isArray(errorDetail)) {
      toast.dismiss(toastId)
      errorList.value = errorDetail
    } else {
      toast.update(toastId, { content: `Failed to upload file: ${errorDetail || error}`, options: { type: TYPE.ERROR } })
    }
    return
  } finally {
    isUploading.value = false
  }

  await updateFileList()
  emit("uploaded")
}

class AssetEnergyFile {
  assetId: number
  uploadedAt: string
  timezone: string | null

  constructor(assetId: number, uploadedAt: string, timezone: string | null) {
    this.assetId = assetId
    this.uploadedAt = uploadedAt
    this.timezone = timezone
  }
}

const files = ref<Array<AssetEnergyFile> | undefined>(undefined)
const updateFileList = async () => {
  if (asset.value.id == 0) {
    files.value = []
  } else {
    files.value = await fetcher.httpGet<Array<AssetEnergyFile>>(`/assets/${asset.value.id}/files/energy`, {})
  }

  emit("update:fileCount", files.value.length)
}

const clearUploads = () => {
  files.value = []
  emit("update:fileCount", files.value.length)
}

onMounted(async () => {
  await updateFileList()
})

watch(
  () => props.asset,
  (newAsset: SiteAsset) => {
    asset.value = newAsset
  }
)
</script>
<style lang="scss"></style>
