import { defineStore } from "pinia"
import { Portfolio } from "@/models/order"
import { ref, computed } from "vue"
import { TYPE, useToast } from "vue-toastification"
import { usePortfolioService } from "@/services/service-container"
import { AccountName } from "@/models/models"

export const useAdminPortfoliosStore = defineStore("adminPortfolios", () => {
  const isLoading = ref<boolean>(true)
  const isSaving = ref<boolean>(false)
  const portfoliosMap = ref<Map<string, Portfolio>>(new Map())

  const portfolioService = usePortfolioService()
  const toast = useToast()

  const loadPortfolios = async () => {
    isLoading.value = true
    try {
      const portfoliosArray = await portfolioService.listPortfoliosAdmin()
      portfoliosMap.value = new Map(portfoliosArray.map((p) => [p.id, p]))
    } finally {
      isLoading.value = false
    }
  }

  const _toastify = <T extends Array<any>, U>(message: string, fn: (...args: T) => U) => {
    return async (...args: T): Promise<U> => {
      isSaving.value = true
      const toastId = toast(`${message}…`, { id: "Portfolios", type: TYPE.DEFAULT, timeout: false })

      try {
        const result = await fn(...args)
        toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
        return result
      } catch (err) {
        toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
        throw err
      } finally {
        isSaving.value = false
      }
    }
  }

  const fetchPortfolio = async (portfolioId: string) => {
    portfoliosMap.value.set(portfolioId, await portfolioService.getPortfolio(portfolioId))
  }

  const createPortfolio = _toastify("Saving", async (portfolio: Portfolio) => {
    const newPortfolio = await portfolioService.addPortfolio(portfolio)
    portfoliosMap.value.set(newPortfolio.id, newPortfolio)
    return newPortfolio
  })

  const updatePortfolio = _toastify("Saving", async (portfolio: Portfolio) => {
    const updatedPortfolio = await portfolioService.updatePortfolio(portfolio)
    portfoliosMap.value.set(updatedPortfolio.id, updatedPortfolio)
    return updatedPortfolio
  })

  const deletePortfolio = _toastify("Deleting", async (portfolio: Portfolio) => {
    await portfolioService.deletePortfolio(portfolio.id)
    portfoliosMap.value.delete(portfolio.id)
  })

  const addSupplier = _toastify("Adding", async (portfolio: Portfolio, supplier: AccountName) => {
    await portfolioService.attachSupplier(portfolio.id, supplier.id, 0)
    portfolio.suppliers.push(supplier)
  })

  const removeSupplier = _toastify("Removing", async (portfolio: Portfolio, supplier: AccountName) => {
    const supplierIndex = portfolio.suppliers.findIndex((s) => s.id == supplier.id)
    if (supplierIndex !== -1) {
      await portfolioService.detachSupplier(portfolio.id, supplier.id)
      portfolio.suppliers.splice(supplierIndex, 1)
    }
  })

  const portfolios = computed(() => Array.from(portfoliosMap.value.values()))

  const getPortfolioById = computed(() => {
    return (id: string) => portfoliosMap.value.get(id)
  })

  // automatically load portfolios on first instantiation
  loadPortfolios()

  return {
    // state
    isLoading,
    isSaving,
    // actions
    loadPortfolios,
    fetchPortfolio,
    createPortfolio,
    updatePortfolio,
    deletePortfolio,
    addSupplier,
    removeSupplier,
    // getters
    portfolios,
    getPortfolioById,
  }
})
