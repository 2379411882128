import { NavigationGuardNext, RouteRecordRaw } from "vue-router"
import { getEnvironment } from "@/environment"

const { WWW_BASE_URL } = getEnvironment()

const EmptyComponent = () => ({})

const redirectToWwwBaseUrl = (_to: unknown, _from: unknown, next: NavigationGuardNext) => {
  location.href = WWW_BASE_URL
  return next(false)
}

export const marketRoutes: Array<RouteRecordRaw> = [
  {
    path: "/openeac-exchange/",
    name: "wc-openeac-exchange",
    beforeEnter: redirectToWwwBaseUrl,
    component: EmptyComponent,
  },
  {
    path: "/marketplace/",
    name: "wc-marketplace",
    beforeEnter: redirectToWwwBaseUrl,
    component: EmptyComponent,
  },
  {
    path: "/openeac-exchange/search",
    name: "wc-openeac-exchange-search",
    beforeEnter: redirectToWwwBaseUrl,
    component: EmptyComponent,
  },
  {
    path: "/marketplace/search",
    name: "wc-marketplace-search",
    beforeEnter: redirectToWwwBaseUrl,
    component: EmptyComponent,
  },
  {
    path: "/stories/:storyId",
    name: "wc-story",
    beforeEnter: ({ path }, _from, next) => {
      location.href = `${WWW_BASE_URL}${path}`
      next(false)
    },
    component: EmptyComponent,
  },
  {
    path: "/openeac-exchange/stories/:storyId",
    name: "wc-openeac-exchange-story",
    redirect: { name: "wc-story" },
  },
  {
    path: "/marketplace/stories/:storyId",
    name: "wc-marketplace-story",
    redirect: { name: "wc-story" },
  },
]
