import ApiFetcher from "@/services/api-fetcher"
import { AdminStory } from "@/models/story"

export default class StoryService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }
  getStory = async (id: number): Promise<AdminStory> => {
    // can include switch statements for the parameter
    const endpoint = `/stories/${id}`
    const result = await this.fetcher.httpGet<AdminStory>(endpoint, { storyId: id })
    return Object.assign(new AdminStory(), result)
  }
  listStoriesAdmin = async (): Promise<Array<AdminStory>> => {
    const endpoint = "/stories/admin"
    return await this.fetcher.httpGet<Array<AdminStory>>(endpoint, {})
  }
  addStory = async (storyRequest: AdminStory) => {
    const endpoint = "/stories"
    return await this.fetcher.httpPost<AdminStory>(endpoint, storyRequest)
  }
  updateStory = async (storyRequest: AdminStory) => {
    const endpoint = `/stories/${storyRequest.id}`
    return await this.fetcher.httpPut<AdminStory>(endpoint, storyRequest)
  }
  uploadImage = async (storyId: number, file: any) => {
    const endpoint = `/stories/${storyId}/image`
    return await this.fetcher.httpUploadPut<AdminStory>(endpoint, { image: file }, file)
  }
  // TODO: Eventually this endpoint will be deprecated, since stories will be archived instead of deleted
  deleteStory = async (storyId: number) => {
    const endpoint = `/stories/${storyId}`
    return await this.fetcher.httpDelete(endpoint, {})
  }
}
