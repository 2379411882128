import ApiFetcher from "@/services/api-fetcher"
import type { PurchaseSummary } from "@/models/listing"
import type { MarketplaceStory, Story } from "@/models/story"
import type { Coordinates } from "@/models/models"
import type { Payment } from "@/services/api/transaction.service"

type DeviceSummary = {
  id: number
  coordinates: Coordinates
  location: string
  city: string
  state: string
  kind: string
  eacCount: number
}

export type StorySummary = {
  story: Story
  devices: DeviceSummary[]
}

export type PurchaseDetails = {
  transactionId: string
  payment: Payment
  timestamp: string
  totalEacs: number
  stories: StorySummary[]
}

export const truncateTransactionId = (transactionId: string) => transactionId.slice(30).toUpperCase()

export default class ListingService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async getAllListings(options: { storyId?: number }): Promise<{ stories: Array<MarketplaceStory> }> {
    return await this.fetcher.httpGet<{ stories: Array<MarketplaceStory> }>("/listings", options)
  }

  async getPurchaseSummaries(): Promise<PurchaseSummary[]> {
    return await this.fetcher.httpGet<PurchaseSummary[]>("/listings/summary", {})
  }

  async getPurchaseDetails(transactionId: string): Promise<PurchaseDetails> {
    return await this.fetcher.httpGet<PurchaseDetails>(`/listings/purchase/${transactionId}`, {})
  }
}
