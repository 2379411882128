<template>
  <div :id="chartId" class="w-full bg-white dark:bg-blue-99"></div>
</template>

<script lang="ts" setup>
// Libraries
import { onMounted, PropType, ref, watch } from "vue"
import { EChartsOption, EChartsType } from "echarts"
import * as echarts from "echarts"

// Models
import { LoadshapeSummaryRow } from "@/models/loadshape"

// Services
import { VizHelper } from "@/components/visualizations/VizHelper"
import { useMainStore } from "@/store"

const store = useMainStore()

const props = defineProps({
  width: { type: String },
  height: { type: String },
  data: { type: Object as PropType<Array<LoadshapeSummaryRow>>, required: true },
})

const chartId = "loadshape-chart-" + new Date()
const visibleSeries = ref(new Set(["Energy Consumed", "Emissions", "Carbon Intensity"])) // Default to showing all series
const option: EChartsOption = {
  legend: {
    data: ["Energy Consumed", "Emissions", "Carbon Intensity"],
    inactiveColor: "#777",
    textStyle: {
      color: store.colors.text,
    },
    right: 0,
    top: 0,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      animation: false,
      type: "cross",
      lineStyle: {
        color: store.colors.highlight,
        width: 2,
        opacity: 1,
      },
    },
    valueFormatter: (value: any) => (value ? `${value.toFixed(1)}` : ""),
  },
  grid: {
    left: 50,
    top: 80,
    right: 80,
    bottom: 80,
  },
  xAxis: {
    type: "category",
    data: [],
    axisLine: { lineStyle: { color: store.colors.grid } },
    axisLabel: {
      rotate: 45,
      color: store.colors.text,
      fontSize: 10,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: [
    {
      type: "value",
      name: "kWh",
      axisLabel: {
        formatter: (value: number) => `${value}`,
        fontSize: 10,
      },
      axisLine: { lineStyle: { color: store.colors.text } },
    },
    {
      type: "value",
      name: "kg",
      axisLabel: {
        fontSize: 10,
        formatter: (value: number) => `${value}`,
      },
      axisLine: { lineStyle: { color: store.colors.text } },
    },
    {
      type: "value",
      name: "kg / MWh",
      show: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      splitLine: { show: false },
      axisPointer: { show: false },
    },
  ],
  series: [
    {
      name: "Energy Consumed",
      type: "bar",
      data: [],
      itemStyle: {
        color: store.colors.energy,
      },
      yAxisIndex: 0,
    },
    {
      name: "Emissions",
      type: "bar",
      data: [],
      itemStyle: {
        color: store.colors.carbon,
      },
      yAxisIndex: 1,
    },
    {
      name: "Carbon Intensity",
      type: "line",
      step: "middle",
      symbol: "none",
      data: [],
      itemStyle: {
        color: store.colors.carbonIntensity,
        opacity: 0,
      },
      lineStyle: {
        color: store.colors.carbonIntensity,
        opacity: 1,
      },
      yAxisIndex: 2,
    },
  ],
}

let chart: EChartsType

const renderChart = (hours: Array<LoadshapeSummaryRow>) => {
  const dates = hours.map((h: LoadshapeSummaryRow) => h.HourLabel)
  const _energy = hours.map((h: LoadshapeSummaryRow) => h.MarketBasedEnergyAvgMwh * 1000)
  const _carbon = hours.map((h: LoadshapeSummaryRow) => h.MarketBasedCarbonAvgKgCo2)
  const _intensity = hours.map((h: LoadshapeSummaryRow) => h.locationalCarbonIntensityKgCo2PerMwh)
  const intervals = 5

  const visibleValues: number[] = []
  if (visibleSeries.value.has("Energy Consumed")) visibleValues.push(..._energy)
  if (visibleSeries.value.has("Emissions")) visibleValues.push(..._carbon)

  const { ticks } = VizHelper.getAxisTicks(Math.min(...visibleValues), Math.max(...visibleValues), intervals)
  const yMin = Math.min(...ticks)
  const yMax = Math.max(...ticks)
  const intensityMax = Math.max(..._intensity)
  const intensityMin = Math.min(..._intensity)
  const intensityWindow = intensityMax - intensityMin
  const intensityWindowPadding = intensityWindow * 0.1 // Add 10% padding above and below the intensity series

  // Leaving this comment in because this was kind of hard to figure out and I'm not sure the tick problem is solved. KW
  // // This is used to make the zeros line up.
  // if (energyMin < 0 && energyMax > 0) {
  //   const ratio = energyMax / carbonMax
  //   carbonMin = energyMin / ratio
  // }

  const showCarbonIntensityAxis = visibleSeries.value.has("Carbon Intensity") && !visibleSeries.value.has("Emissions")

  chart.setOption({
    xAxis: {
      data: dates,
    },
    yAxis: [
      {
        type: "value",
        min: yMin,
        max: yMax,
      },
      {
        type: "value",
        min: yMin,
        max: yMax,
        show: visibleSeries.value.has("Emissions"),
      },
      {
        type: "value",
        min: Math.round(intensityMin - intensityWindowPadding),
        max: Math.round(intensityMax + intensityWindowPadding),
        show: showCarbonIntensityAxis,
        axisPointer: { show: showCarbonIntensityAxis },
      },
    ],
    series: [
      {
        // Find series by name
        name: "Energy Consumed",
        data: _energy,
        show: visibleSeries.value.has("Energy Consumed"),
      },
      {
        // Find series by name
        name: "Emissions",
        data: _carbon,
        show: visibleSeries.value.has("Emissions"),
      },
      {
        // Find series by name
        name: "Carbon Intensity",
        data: _intensity,
        show: visibleSeries.value.has("Carbon Intensity"),
      },
    ],
  })
}

// Keep track of which series are toggled on/off
const handleLegendSelectChanged = function (this: EChartsType, ...args: unknown[]) {
  const event = args[0] as { selected: Record<string, boolean> }
  visibleSeries.value = new Set(
    Object.entries(event.selected)
      .filter(([_, isSelected]) => isSelected)
      .map(([name]) => name)
  )
  renderChart(props.data)
}

onMounted(() => {
  const chartDom = document.getElementById(chartId)
  if (!chartDom) {
    return
  }
  chart = echarts.init(chartDom, undefined, {
    width: props.width,
    height: props.height,
  })
  if (option) {
    chart.setOption(option)
  }
  chart.on("legendselectchanged", handleLegendSelectChanged)
  renderChart(props.data)
  new ResizeObserver(() => chart.resize()).observe(chartDom)
})

watch(
  () => props.data,
  (value: Array<LoadshapeSummaryRow>) => {
    renderChart(value)
  }
)
</script>
